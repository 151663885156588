import axiosInstance from "../../utils/AxiosInstance";

export function changePassword(oldPassword: string, newPassword: string): Promise<Boolean> {
  return axiosInstance
    .post<Boolean>("/change-password", {
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
    .then((response) => {
      return response.data;
    });
}
