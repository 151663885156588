import { Button, Card, CardProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Application } from "../../../types/Application";
import { Box } from "@mui/system";
import DisplayedValue from "../../../components/DisplayedValue";
import { formatDateToString } from "../../../utils/DateUtils";
import { OpenInNew } from "@mui/icons-material";

export interface ApplicationInfoModuleProps extends CardProps {
  maxWidth: number;
  application: Application;
}

export const ApplicationInfoModule: React.FC<ApplicationInfoModuleProps> = ({ maxWidth, application, ...cardProps }) => {
  const { t } = useTranslation();
  const appliedDate = application.appliedDate;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: maxWidth }}>
      <Card
        sx={{
          width: "100%",
          maxWidth: maxWidth,
          mb: 6,
          pt: 2,
          pb: 4,
          px: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t("applicationDetails.page.detailsTitle")}
        </Typography>
        <DisplayedValue name={t("applicationDetails.page.workplace")} value={application.workplace} />
        <DisplayedValue name={t("applicationDetails.page.role")} value={application.role} />
        {appliedDate && <DisplayedValue name={t("applicationDetails.page.appliedAt")} value={formatDateToString(appliedDate)} />}

        <Box sx={{ width: "100%", display: "flex" }}>
          {application?.adUrl && (
            <Button
              variant="outlined"
              startIcon={<OpenInNew />}
              color="primary"
              onClick={() => window.open(application.adUrl, "_blank")}
              sx={{ mt: 4, mb: 2 }}
            >
              {t("applicationDetails.page.adUrl")}
            </Button>
          )}
        </Box>
      </Card>
    </Box>
  );
};
