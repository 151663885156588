import { Button, Checkbox, List, ListItem, ListItemText, Popover } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useState } from "react";
import { OccupationGroupFilter } from "../../../types/OccupationGroupFilter";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { occupationGroupFilters } from "./PlatsbankenFIlterData";
import { Box } from "@mui/system";
import { setSearchOccupationFilter, setSearchOccupationGroupFilter } from "../../../reducers/applicationPageSlice";
import { OccupationFilter } from "../../../types/OccupationFilter";
import { useTranslation } from "react-i18next";

// THIS FILE IS  PROBABLY THE WORSRT COPY PASTE I HAVE EVER DONE
// If changes are needed, do them in location filter and use AI to translate them here

function occupationGroupContainsSelectedOccupation(group: OccupationGroupFilter, allSelectedOccupations: OccupationFilter[]): boolean {
  return group.occupations.some((occupation) => allSelectedOccupations.includes(occupation));
}

function hasOccupationFilter(groups: OccupationGroupFilter[], occupations: OccupationFilter[]): boolean {
  return groups.length > 0 || occupations.length > 0;
}

function hasAllOccupationsInGroup(group: OccupationGroupFilter, allSelectedOccupations: OccupationFilter[]): boolean {
  return group.occupations.every((occupation) => allSelectedOccupations.includes(occupation));
}

export const OccupationFilterSelector: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const selectedOccupationGroups = useAppSelector((state) => state.applicationPage.searchOccupationGroupFilter);
  const selectedOccupations = useAppSelector((state) => state.applicationPage.searchOccupationFilter);
  const [activeOccupationGroup, setActiveOccupationGroup] = useState<OccupationGroupFilter | null>(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    dispatch(setSearchOccupationGroupFilter(selectedOccupationGroups));
    dispatch(setSearchOccupationFilter(selectedOccupations));
  };

  const handleOccupationGroupClick = (group: OccupationGroupFilter) => {
    setActiveOccupationGroup(activeOccupationGroup === group ? null : group);
  };

  const setSelectedOccupationGroups = (groups: OccupationGroupFilter[]) => {
    dispatch(setSearchOccupationGroupFilter(groups));
  };
  const setSelectedOccupations = (occupations: OccupationFilter[]) => {
    dispatch(setSearchOccupationFilter(occupations));
  };

  const handleSelectAllOccupationGroupClick = (group: OccupationGroupFilter) => {
    const isCurrentlySelected = selectedOccupationGroups.some((g) => g.id === group.id);
    let updatedOccupations = [...selectedOccupations];

    if (isCurrentlySelected) {
      // Remove the group and its occupations
      setSelectedOccupationGroups(selectedOccupationGroups.filter((g) => g.id !== group.id));
      const groupOccupationIds = group.occupations.map((o) => o.id);
      updatedOccupations = updatedOccupations.filter((o) => !groupOccupationIds.includes(o.id));
    } else {
      // Add the group and its occupations
      setSelectedOccupationGroups([...selectedOccupationGroups, group]);
      const newOccupations = group.occupations.filter((o) => !updatedOccupations.some((selectedO) => selectedO.id === o.id));
      updatedOccupations = [...updatedOccupations, ...newOccupations];
    }

    setSelectedOccupations(updatedOccupations);
  };

  const handleOccupationToggle = (occupation: OccupationFilter) => {
    let updatedOccupations = [...selectedOccupations];
    const isCurrentlySelected = selectedOccupations.some((o) => o.id === occupation.id);

    if (isCurrentlySelected) {
      // Remove the occupation if it is currently selected
      updatedOccupations = updatedOccupations.filter((o) => o.id !== occupation.id);

      // Check if the occupation is part of any selected group
      const groupContainingOccupation = selectedOccupationGroups.find((group) => group.occupations.some((o) => o.id === occupation.id));
      if (groupContainingOccupation) {
        // Remove the group from selected groups
        setSelectedOccupationGroups(selectedOccupationGroups.filter((group) => group.id !== groupContainingOccupation.id));

        // Add all occupations of that group to selected occupations except the toggled one
        const newOccupations = groupContainingOccupation.occupations.filter((o) => o.id !== occupation.id).map((o) => ({ ...o }));

        updatedOccupations = [...updatedOccupations, ...newOccupations];
      }
    } else {
      // Add the toggled occupation
      updatedOccupations.push(occupation);
      // Check if all occupations of any group are selected
      const groupContainingOccupation = occupationGroupFilters.find((group) => group.occupations.some((o) => o.id === occupation.id));
      if (groupContainingOccupation) {
        const allOccupationsSelected = groupContainingOccupation.occupations.every((o) =>
          updatedOccupations.some((selectedO) => selectedO.id === o.id)
        );
        if (allOccupationsSelected) {
          setSelectedOccupationGroups([...selectedOccupationGroups, groupContainingOccupation]);
        }
      }
    }

    setSelectedOccupations(updatedOccupations);
  };

  const open = Boolean(anchorEl);
  const id = open ? "occupation-selector-popover" : undefined;

  return (
    <div>
      <Button
        variant={anchorEl || hasOccupationFilter(selectedOccupationGroups, selectedOccupations) ? "outlined" : "contained"}
        onClick={handleButtonClick}
      >
        {hasOccupationFilter(selectedOccupationGroups, selectedOccupations) && (
          <Box width={10} height={10} borderRadius="50%" bgcolor={"orange"} mr={2}></Box>
        )}
        {t("application.jobSearch.occupation")}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            maxHeight: 400,
            overflowY: "auto",
          },
        }}
        disableScrollLock={true}
      >
        <div style={{ display: "flex" }}>
          <List style={{ minWidth: 150 }}>
            {occupationGroupFilters.map((group) => (
              <div key={group.name}>
                <ListItem button onClick={() => handleOccupationGroupClick(group)}>
                  <ListItemText primary={group.name} />
                  {occupationGroupContainsSelectedOccupation(group, selectedOccupations) && (
                    <Box width={10} height={10} borderRadius="50%" bgcolor={"orange"} mr={2}></Box>
                  )}
                  {activeOccupationGroup === group ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              </div>
            ))}
          </List>
          {activeOccupationGroup && (
            <List style={{ minWidth: 200 }}>
              <ListItem button onClick={() => handleSelectAllOccupationGroupClick(activeOccupationGroup)}>
                <Checkbox
                  checked={selectedOccupationGroups.includes(activeOccupationGroup)}
                  indeterminate={
                    occupationGroupContainsSelectedOccupation(activeOccupationGroup, selectedOccupations) &&
                    !hasAllOccupationsInGroup(activeOccupationGroup, selectedOccupations) &&
                    !selectedOccupationGroups.includes(activeOccupationGroup)
                  }
                />
                <ListItemText primary={t("application.jobSearch.selectAllOccupations")} />
              </ListItem>
              {activeOccupationGroup.occupations.map((occupation) => (
                <ListItem key={occupation.id} button onClick={() => handleOccupationToggle(occupation)}>
                  <Checkbox checked={selectedOccupations.includes(occupation)} />
                  <ListItemText primary={occupation.name} />
                </ListItem>
              ))}
            </List>
          )}
        </div>
      </Popover>
    </div>
  );
};
