import React from "react";
import { useSelector } from "react-redux";
import { Box, FormControlLabel, List, ListItem, ListItemText, Radio, RadioGroup, Typography } from "@mui/material";
import { RootState } from "../../store";
import { postSelfAssessmentAnswer as apiPostSelfAssessmentAnswer } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { setSelfAssessmentQuestionAnswer } from "../../reducers/profilePageSlice";
import { SelfAssessmentQuestion } from "../../types/SelfAssessment";
import { useTranslation } from "react-i18next";

const SelfAssessmentComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const questions = useSelector((state: RootState) => state.profilePage.selfAssessmentQuestions);

  const onAnswer = async (id: string, answer: string) => {
    try {
      dispatch(
        setSelfAssessmentQuestionAnswer({
          questionId: id,
          answer: answer,
        })
      );
      await apiPostSelfAssessmentAnswer({
        questionId: id,
        answer: answer,
      });
      dispatch(showSnackbar({ message: t("profilePage.selfAssessment.answerSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.selfAssessment.answerError"), severity: "error" }));
    }
  };

  return (
    <Box sx={{ textAlign: "center", p: 2, height: "100%" }}>
      {questions && questions.length > 0 ? <QuestionsList questions={questions} onAnswer={onAnswer} /> : <EmptyState />}
    </Box>
  );
};

interface QuestionsListProps {
  questions: SelfAssessmentQuestion[];
  onAnswer: (id: string, answer: string) => void;
}

const QuestionsList: React.FC<QuestionsListProps> = ({ questions, onAnswer }) => {
  const { t } = useTranslation();

  const handleRadioChange = (questionId: string, answer: string) => {
    onAnswer(questionId, answer);
  };

  const calculateWidth = (numberOfAnswers: number): string => {
    const widthPercentage = 100 / numberOfAnswers - 1; // Subtract 1% to account for spacing/margins
    return `${widthPercentage}%`;
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Typography variant="h6" sx={{ fontWeight: "bold", alignSelf: "flex-start" }}>
        {t("profilePage.selfAssessment.title")}
      </Typography>
      <List sx={{ flex: 1 }}>
        {questions.map((question, index) => (
          <ListItem key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", py: 1, pl: 0, mb: 5 }}>
            <ListItemText primary={question.question} primaryTypographyProps={{ variant: "body1", fontWeight: "bold" }} />
            <RadioGroup
              name={`question-${question.id}`}
              value={question.answer || ""}
              onChange={(event) => handleRadioChange(question.id, (event.target as HTMLInputElement).value)}
              sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}
            >
              {question?.allowedAnswers?.map((answer, index) => (
                <FormControlLabel
                  key={index}
                  value={answer}
                  control={<Radio size="small" />}
                  label={answer}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: calculateWidth(question.allowedAnswers.length),
                    mx: 0.5,
                    minWidth: "100px",
                  }}
                />
              ))}
            </RadioGroup>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const EmptyState: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
        p: 2,
      }}
    >
      <Typography variant="h6">{t("profilePage.selfAssessment.emptyStateTitle")}</Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        {t("profilePage.selfAssessment.emptyStateMessage")}
      </Typography>
    </Box>
  );
};

export default SelfAssessmentComponent;
