import { NavigateFunction } from "react-router-dom";
import LoginPage from "./pages/login/LoginPage";
import ProfilePage from "./pages/profile/ProfilePage";
import ManageAccountPage from "./pages/profile/ProfilePage";
import ChangePasswordPage from "./pages/change-password/ChangePasswordPage";
import ForgotPasswordPage from "./pages/forgot-password/ForgotPasswordPage";
import ApplicationPage from "./pages/application/ApplicationPage";
import ApplicationDetailsPage from "./pages/application-details/ApplicationDetailsPage";
import ApplicationsListPage from "./pages/applications-list/ApplicationsListPage";
import { AccountCircle,  } from "@mui/icons-material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DraftsIcon from '@mui/icons-material/Drafts';

interface MaijaRoute {
  icon: any;
  path: string;
  nameKey: string;
  component: React.ComponentType;
}

export const LOGIN_ROUTE = {
  path: "/login",
  nameKey: "route.login",
  component: LoginPage,
  icon: "",
};

export const PROFILE_ROUTE = {
  path: "/profile",
  nameKey: "route.profile",
  component: ProfilePage,
  icon: <AccountCircle />,
};

export const APPLICATION_ROUTE = {
  path: "/application",
  nameKey: "route.application",
  component: ApplicationPage,
  icon: <ArrowForwardIosIcon />
};

export const APPLICATION_DETAILS_ROUTE = {
  path: "/application/details/:id",
  nameKey: "route.application_details",
  component: ApplicationDetailsPage,
  icon: "",
};

export const APPLICATION_LIST_ROUTE = {
  path: "/applications-list",
  nameKey: "route.application_list",
  component: ApplicationsListPage,
  icon: <DraftsIcon />
};

export const MANAGE_ACCOUNT_ROUTE = {
  path: "/manage",
  nameKey: "route.manage_account",
  component: ManageAccountPage,
  icon: "",
};

export const FORGOT_PASSWORD_ROUTE = {
  path: "/forgot-password",
  nameKey: "route.forgot_password",
  component: ForgotPasswordPage,
  icon: "",
};

export const CHANGE_PASSWORD_ROUTE = {
  path: "/change-password",
  nameKey: "route.change_password",
  component: ChangePasswordPage,
  icon: "",
};

export const maijaRoutes: MaijaRoute[] = [
  PROFILE_ROUTE,
  APPLICATION_ROUTE,
  APPLICATION_DETAILS_ROUTE,
  APPLICATION_LIST_ROUTE,
  MANAGE_ACCOUNT_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
];

export function getMaijaRoutes(): MaijaRoute[] {
  return maijaRoutes;
}

export function navigateToApplicationDetails(navigateFunction: NavigateFunction, id: string) {
  navigateFunction(`/application/details/${id}`);
}

export default MaijaRoute;
