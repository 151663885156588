import React from "react";
import { Box, Typography, Button } from "@mui/material";
import MaijaModal from "../../../components/MaijaModal";
import { useTranslation } from "react-i18next";

export interface ConfirmationModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const ChangeApplicationModal: React.FC<ConfirmationModalProps> = ({ isOpen, handleClose, handleConfirm }) => {
  const { t } = useTranslation();
  return (
    <MaijaModal isOpen={isOpen} handleClose={handleClose} sx={{ width: "60vw", maxWidth: "800px" }}>
      <Typography id="confirmation-modal-title" variant="h6" component="h2">
        {t("applicationDetails.page.changeApplicationConfirmation.title")}
      </Typography>
      <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
        {t("applicationDetails.page.changeApplicationConfirmation.description")}
      </Typography>
      <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          {t("generic.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          {t("generic.confirm")}
        </Button>
      </Box>
    </MaijaModal>
  );
};
