import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks";
import MaijaRoute, { LOGIN_ROUTE, PROFILE_ROUTE, APPLICATION_ROUTE, APPLICATION_LIST_ROUTE } from "../Routes";
import ApplicantAppBar from "./ApplicantAppBar";

type ProtectedRouteProps = {
  route: MaijaRoute;
  children: React.ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ route, children }) => {
  const user = useAppSelector((state) => state.user.user);
  const location = useLocation();

  if (!user) {
    return <Navigate to={LOGIN_ROUTE.path} replace state={{ from: location }} />;
  }

  const toolbarRoutes = [PROFILE_ROUTE, APPLICATION_ROUTE, APPLICATION_LIST_ROUTE];

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <ApplicantAppBar routes={toolbarRoutes} />
      <div style={{ flexGrow: 1, overflow: 'auto' }}>
        {children}
      </div>
    </div>
  );
};

export default ProtectedRoute;
