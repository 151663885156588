import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Cookies from "js-cookie";

import translationEN from "./locales/en/translation.json";
import translationSV from "./locales/sv/translation.json";
import translationDE from "./locales/de/translation.json";
import translationAR from "./locales/ar/translation.json";
import translationSO from "./locales/so/translation.json";
import translationFA from "./locales/fa/translation.json";
import translationKU from "./locales/ku/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationFI from "./locales/fi/translation.json";
import translationNO from "./locales/no/translation.json";
import translationTI from "./locales/ti/translation.json";
import translationZH from "./locales/zh/translation.json";
import translationVI from "./locales/vi/translation.json";
import translationDA from "./locales/da/translation.json";
import translationPL from "./locales/pl/translation.json";

const savedLanguage = localStorage.getItem("language") || Cookies.get("language");

const resources = {
  en: {
    translation: translationEN,
  },
  sv: {
    translation: translationSV,
  },
  de: {
    translation: translationDE,
  },
  ar: {
    translation: translationAR,
  },
  so: {
    translation: translationSO,
  },
  fa: {
    translation: translationFA,
  },
  ku: {
    translation: translationKU,
  },
  fr: {
    translation: translationFR,
  },
  fi: {
    translation: translationFI,
  },
  no: {
    translation: translationNO,
  },
  ti: {
    translation: translationTI,
  },
  zh: {
    translation: translationZH,
  },
  vi: {
    translation: translationVI,
  },
  da: {
    translation: translationDA,
  },
  pl: {
    translation: translationPL,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: savedLanguage || "sv", // default language
  fallbackLng: "sv",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
