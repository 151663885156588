export enum ApplicationTone {
  Formal = "formal",
  Casual = "casual",
  Friendly = "friendly",
  Professional = "professional",
  Humorous = "humorous",
  Direct = "direct",
}

export default ApplicationTone;
