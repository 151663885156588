import React, { useState } from "react";
import { Card, CardContent, TextField, Button, Box, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { useTranslation } from "react-i18next";

export interface JobAdLinkFormProps {
  handleGenerateJobAdApplication: (link: string) => void;
}

const JobAdLinkForm: React.FC<JobAdLinkFormProps> = (props: JobAdLinkFormProps) => {
  const { t } = useTranslation();
  const [jobAdLink, setJobAdLink] = useState<string>("");
  const [isLinkValid, setIsLinkValid] = useState<boolean>(true);

  const handleJobAdLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const link = event.target.value;
    setJobAdLink(link);
    setIsLinkValid(isValidUrl(link) || link === "");
  };

  const isButtonDisabled = !isLinkValid || jobAdLink.trim() === "";

  const handleGenerateApplication = () => {
    if (isLinkValid && jobAdLink) {
      props.handleGenerateJobAdApplication(jobAdLink);
      console.log(handleGenerateApplication);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleGenerateApplication();
    }
  };

  return (
    <Card sx={{ margin: "auto", mb: 2, p: 3, maxWidth: 800, borderRadius: 5 }}>
      <CardContent>
        <Typography variant="h6" align="left" sx={{ mb: 4, mt: -2, fontWeight: 550 }}>
          {t("application.jobLink.title")}
        </Typography>
        <Typography variant="subtitle1" align="left" sx={{ mb: 2 }}>
          {t("application.jobLink.subtitle")}
        </Typography>

        <Box
          sx={{
            "& .MuiTextField-root": { mb: 2, width: "100%" },
            "& .MuiButton-root": { padding: "10px 20px" },
          }}
        >
          <TextField
            required
            id="job-ad-link-field"
            label={t("application.jobLink.jobAdLinkLabel")}
            type="url"
            value={jobAdLink}
            onChange={handleJobAdLinkChange}
            onKeyDown={handleKeyPress}
          />

          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={isButtonDisabled}
            onClick={handleGenerateApplication}
            sx={{ mt: 6 }}
            startIcon={<LinkIcon />}
          >
            {t("application.jobLink.generateButton")}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

function isValidUrl(urlString: string): boolean {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i" // fragment locator
  );
  return !!pattern.test(urlString);
}

export default JobAdLinkForm;
