import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  Typography,
  List,
  ListItem,
  CircularProgress,
  Autocomplete,
  Pagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { JobAdListItem } from "../../../types/JobAdListItem";
import { getJobSearchSuggestions, searchJobAds } from "../ApplicationRepository";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setCurrentPage, setSearchQuery, clearFilters, setSearchResults, setSearchTotal } from "../../../reducers/applicationPageSlice";
import { LocationFilterSelector } from "./LocationFilterSelector";
import { OccupationFilterSelector } from "./OccupationFilterSelector";
import { OpenInNew, WorkOutline } from "@mui/icons-material";

export interface JobListAdSearchFormProps {
  handleGenerateJobListAdApplication: (jobAdId: string) => void;
}

const JobListAdSearchForm: React.FC<JobListAdSearchFormProps> = (props: JobListAdSearchFormProps) => {
  const PAGE_SIZE = 10;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const cahcedQuery = useAppSelector((state) => state.applicationPage.searchQuery);
  const [query, setQuery] = useState<string>(cahcedQuery || "");
  const currentPage = useAppSelector((state) => state.applicationPage.searchCurrentPage);
  const searchTotal = useAppSelector((state) => state.applicationPage.searchTotal);

  const regionFilter = useAppSelector((state) => state.applicationPage.searchRegionFilter);
  const municipalityFilter = useAppSelector((state) => state.applicationPage.searchMunicipalityFilter);
  const occupationGroupFilter = useAppSelector((state) => state.applicationPage.searchOccupationGroupFilter);
  const occupationFilter = useAppSelector((state) => state.applicationPage.searchOccupationFilter);

  const [jobAds, setJobAds] = useState<JobAdListItem[]>(useAppSelector((state) => state.applicationPage.searchResults || []));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState<boolean>(false);

  const handleSearch = async (startIndex = 0) => {
    setIsLoading(true);

    const regionIds = regionFilter?.map((region) => region.id);
    let municipalityIds = municipalityFilter?.map((municipality) => municipality.id) || [];
    // Filter out municipalities that belong to the selected regions
    if (regionFilter && regionFilter.length > 0) {
      const regionMunicipalityIds = regionFilter.flatMap((region) => region.municipalities.map((m) => m.id));
      municipalityIds = municipalityIds.filter((id) => !regionMunicipalityIds.includes(id));
    }

    const occupationGroupIds = occupationGroupFilter?.map((group) => group.id);
    let occupationIds = occupationFilter?.map((occupation) => occupation.id) || [];
    // Filter out occupations that belong to the selected occupation groups
    if (occupationGroupFilter && occupationGroupFilter.length > 0) {
      const groupOccupationIds = occupationGroupFilter.flatMap((group) => group.occupations.map((o) => o.id));
      occupationIds = occupationIds.filter((id) => !groupOccupationIds.includes(id));
    }

    try {
      const results = await searchJobAds({
        query: query,
        regions: regionIds,
        municipalities: municipalityIds,
        occupationGroups: occupationGroupIds,
        occupationFields: occupationIds,
        maxRecords: PAGE_SIZE,
        startIndex: startIndex,
      });

      if (!results) {
        return;
      }

      if (startIndex == 0) {
        dispatch(setCurrentPage(1));
      }

      dispatch(setSearchQuery(query));
      dispatch(setSearchResults(results.ads));
      dispatch(setSearchTotal(results.numberOfAds));

      setJobAds(results.ads);
    } catch (error) {
      console.error("Error searching job ads:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (query && query.length > 2) {
      const fetchSuggestions = async () => {
        setIsSuggestionsLoading(true);
        try {
          const result = await getJobSearchSuggestions(query);
          setSuggestions(result.suggestions);
        } catch (error) {
          console.error("Error fetching suggestions & display it:", error);
        }
        setIsSuggestionsLoading(false);
      };

      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [query]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setCurrentPage(value));
    handleSearch((value - 1) * PAGE_SIZE);
  };

  return (
    <Card sx={{ margin: "auto", mb: 2, p: 3, maxWidth: 800, borderRadius: 5 }}>
      <CardContent>
        <Typography variant="h6" align="left" sx={{ mb: 4, mt: -2, fontWeight: 550 }}>
          {t("application.jobSearch.title")}
        </Typography>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { mb: 2, width: "100%" },
            "& .MuiButton-root": { padding: "10px 20px" },
          }}
          noValidate
          autoComplete="off"
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Autocomplete
              id="search-query-field"
              options={suggestions}
              loading={isSuggestionsLoading}
              onInputChange={(event, value) => {
                setQuery(value || "");
              }}
              sx={{ width: "100%" }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("application.jobSearch.queryLabel")}
                  variant="outlined"
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSearch();
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isSuggestionsLoading && suggestions.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleSearch(0)}
              sx={{ ml: 3, height: 56, px: 3, py: 4 }}
              startIcon={<SearchIcon />}
              disabled={isLoading}
            >
              {t("application.jobSearch.searchButton")}
            </Button>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }} alignItems="center" gap={2}>
            <LocationFilterSelector />
            <OccupationFilterSelector />
            <Button variant="text" color="error" onClick={() => dispatch(clearFilters())}>
              {t("application.jobSearch.clearFiltersButton")}
            </Button>
          </Box>
        </Box>
        <JobAdListComponent
          jobAds={jobAds}
          handleGenerateJobListAdApplication={props.handleGenerateJobListAdApplication}
          searchTotal={searchTotal}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          PAGE_SIZE={PAGE_SIZE}
        />
      </CardContent>
    </Card>
  );
};

interface JobAdListComponentProps {
  jobAds: JobAdListItem[];
  handleGenerateJobListAdApplication: (jobAdId: string) => void;
  searchTotal: number | undefined;
  currentPage: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  PAGE_SIZE: number;
}

const JobAdListComponent: React.FC<JobAdListComponentProps> = ({
  jobAds,
  handleGenerateJobListAdApplication,
  searchTotal,
  currentPage,
  handlePageChange,
  PAGE_SIZE,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {jobAds.length > 0 ? (
        <List>
          {jobAds.map((jobAd) => (
            <JobAdListItemComponent key={jobAd.id} jobAd={jobAd} handleGenerateJobListAdApplication={handleGenerateJobListAdApplication} />
          ))}
        </List>
      ) : (
        searchTotal !== undefined && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mt: 4,
              minHeight: 300,
            }}
          >
            <WorkOutline sx={{ fontSize: 200, color: "grey.500" }} />
            <Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
              {t("application.jobSearch.noResults")}
            </Typography>
          </Box>
        )
      )}
      {jobAds.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          {searchTotal != undefined && searchTotal > 0 && (
            <Pagination count={Math.ceil(searchTotal / PAGE_SIZE)} page={currentPage} onChange={handlePageChange} />
          )}
        </Box>
      )}
    </>
  );
};

interface JobAdListItemProps {
  jobAd: JobAdListItem;
  handleGenerateJobListAdApplication: (jobAdId: string) => void;
}

const JobAdListItemComponent: React.FC<JobAdListItemProps> = ({ jobAd, handleGenerateJobListAdApplication }) => {
  const { t } = useTranslation();

  const handleViewJobDetails = (id: string) => {
    const url = `https://arbetsformedlingen.se/platsbanken/annonser/${id}`;
    window.open(url, "_blank");
  };

  return (
    <ListItem key={jobAd.id} sx={{ border: "1px solid #e0e0e0", borderRadius: 1, mb: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", p: 2 }}>
        <Typography variant="h6" sx={{}}>
          {jobAd.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {jobAd.workplace} - {jobAd.location}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          {jobAd.role}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button variant="contained" color="primary" onClick={() => handleGenerateJobListAdApplication(jobAd.id)} sx={{ mr: 2 }}>
            {t("application.jobSearch.makeApplicationButton")}
          </Button>
          <Button color="secondary" startIcon={<OpenInNew />} onClick={() => handleViewJobDetails(jobAd.id)}>
            {t("application.jobSearch.viewDetailsButton")}
          </Button>
        </Box>
      </Box>
    </ListItem>
  );
};

export default JobListAdSearchForm;
