import { Box, Skeleton, Paper, styled, TextField, Typography, Button } from "@mui/material";
import React, { useRef, useLayoutEffect, useState, ReactNode, useEffect } from "react";
import MaijaModal from "../../../components/MaijaModal";
import { getCoverLetterPreview, updateCoverLetter } from "../../application/ApplicationRepository";
import { showSnackbar } from "../../../reducers/snackbarSlice";
import { useAppDispatch } from "../../../hooks";
import { updateCoverLetterThumbnail } from "../../../reducers/applicantDetilsPageSlice";
import { useTranslation } from "react-i18next";

interface CoverLetterPreviewModalProps {
  isOpen: boolean;
  coverLetterId: string;
  applicationId: string;
  handleClose: () => void;
}

const calculateHeightFromWidth = (width: number) => {
  return width * 1.414; // A4 aspect ratio (21cm/29.7cm)
};

const StyledA4Paper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  width: "100%",
});

interface A4PaperProps {
  children?: ReactNode;
  loading?: boolean; // Prop to determine if a skeleton should be displayed
}

const A4Paper: React.FC<A4PaperProps> = ({ children, loading = false }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<string>("auto");

  const updateHeight = () => {
    if (ref.current) {
      const width = ref.current.offsetWidth;
      setHeight(`${calculateHeightFromWidth(width)}px`);
    }
  };

  useLayoutEffect(() => {
    updateHeight(); // Initial update
    window.addEventListener("resize", updateHeight); // Add resize listener

    return () => {
      window.removeEventListener("resize", updateHeight); // Clean up listener on unmount
    };
  }, []);

  // Choose to render either the skeleton or the provided children
  const content = loading ? <Skeleton variant="rectangular" width="100%" height={height} animation="wave" /> : children;

  return (
    <StyledA4Paper ref={ref} style={{ height }}>
      {content}
    </StyledA4Paper>
  );
};

const CoverLetterPreviewModal: React.FC<CoverLetterPreviewModalProps> = ({ isOpen, coverLetterId, applicationId, handleClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const [content, setContent] = useState<string>();
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setIsLoading(true);
    const fetchCoverLetterPreview = async () => {
      try {
        const response = await getCoverLetterPreview(coverLetterId);
        setThumbnails(response.thumbnails);
        setContent(response.letterContent);
      } catch (error) {
        console.error("Failed to load document thumbnails:", error);
        dispatch(
          showSnackbar({
            message: t("applicationDetails.coverLetterPreviewModal.errorLoading"),
            severity: "error",
          })
        );
        handleClose();
      } finally {
        setIsLoading(false);
      }
    };
    fetchCoverLetterPreview();
  }, [isOpen]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const response = await updateCoverLetter(coverLetterId, content || "");
      setThumbnails(response.thumbnails);
      if (response.thumbnails.length >= 1) {
        dispatch(
          updateCoverLetterThumbnail({
            applicationId: applicationId,
            coverLetterThumbnail: response.thumbnails[0],
          })
        );
      }
      dispatch(
        showSnackbar({
          message: t("applicationDetails.coverLetterPreviewModal.successUpdating"),
          severity: "success",
        })
      );
    } catch (error) {
      console.error("Error updating cover letter:", error);
      dispatch(
        showSnackbar({
          message: t("applicationDetails.coverLetterPreviewModal.errorUpdating"),
          severity: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MaijaModal isOpen={isOpen} handleClose={handleClose} width="90%" maxHeight="95vh" overflowY="visible" p={0}>
      <Box sx={{ display: "flex", height: "90vh", maxHeight: "95vh" }}>
        <Box
          sx={{
            flex: 1,
            p: 5,
            overflowY: "auto",
          }}
        >
          <Typography variant="h6" sx={{ mb: 5 }}>
            {t("applicationDetails.coverLetterPreviewModal.title")}
          </Typography>
          <TextField
            inputRef={textFieldRef}
            label={t("applicationDetails.coverLetterPreviewModal.editLabel")}
            multiline
            rows={22}
            variant="outlined"
            fullWidth
            value={content}
            onChange={(e) => setContent(e.target.value)}
            sx={{ mb: 7 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            sx={{
              width: "50%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {t("applicationDetails.coverLetterPreviewModal.saveButton")}
          </Button>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            alignItems: "center",
            pt: 18, // Ugly solution to make it match the height of the text box
            pb: 5,
            px: 5,
          }}
        >
          <>
            {thumbnails.map((thumbnail, index) => {
              return (
                <Box key={index} sx={{ mb: 2, width: "100%" }}>
                  <A4Paper loading={isLoading}>
                    {!isLoading && (
                      <img
                        src={`data:image/jpeg;base64,${thumbnail}`}
                        alt={`Cover Letter Page ${index + 1}`}
                        style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                      />
                    )}
                  </A4Paper>
                </Box>
              );
            })}
            {isLoading && thumbnails.length === 0 && <A4Paper loading={true} />}
          </>
        </Box>
      </Box>
    </MaijaModal>
  );
};

export default CoverLetterPreviewModal;
