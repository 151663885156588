import userSlice from "./reducers/userSlice";
import snackbarSlice from "./reducers/snackbarSlice";
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import applicationPageSlice from "./reducers/applicationPageSlice";
import profilePageSlice from "./reducers/profilePageSlice";
import applicantDetailsPageSlice from "./reducers/applicantDetilsPageSlice";
import applicationsListPageSlice from "./reducers/applicationsListPageSlice";
import themeReducer from './reducers/themeSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    snackbar: snackbarSlice,
    applicationPage: applicationPageSlice,
    applicationDetailsPage: applicantDetailsPageSlice,
    applicationsListPage: applicationsListPageSlice,
    profilePage: profilePageSlice,
    theme: themeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export default store;
