export type ApplicationLanguage = {
  code: string;
  name: string;
  flag?: string;
};

export const SUPPORTED_LANGUAGES: ApplicationLanguage[] = [
  { code: "sv", name: "Svenska", flag: "SE" },
  { code: "en", name: "English", flag: "GB" },
  { code: "ar", name: "العربية", flag: "SA" },
  { code: "da", name: "Dansk", flag: "DK" },
  { code: "de", name: "Deutsch", flag: "DE" },
  { code: "fi", name: "Suomi", flag: "FI" },
  { code: "fr", name: "Français", flag: "FR" },
  { code: "ku", name: "Kurdî", flag: "IQ" },
  { code: "pl", name: "Polski", flag: "PL" },
  { code: "so", name: "Soomaali", flag: "SO" },
  { code: "ti", name: "ትግርኛ", flag: "ER" },
  { code: "vi", name: "Tiếng Việt", flag: "VN" },
  { code: "zh", name: "中文", flag: "CN" },
  { code: "no", name: "Norsk", flag: "NO" },
  { code: "fa", name: "فارسی", flag: "IR" },
  { code: "fil", name: "Filipino", flag: "PH" },
];

export function getLanguageByCode(code: string): ApplicationLanguage {
  return SUPPORTED_LANGUAGES.find((language) => language.code === code) || { code: "default", name: "" };
}

export default ApplicationLanguage;
