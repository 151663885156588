import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { Application } from "../types/Application";

export interface ApplicationDetails {
  application: Application;
  pendingApplication?: Application;
  resumeThumbnail?: string;
  coverLetterThumbnail?: string;
  openedFromApplicationsList: boolean;
}

interface ApplicationDetailsPageState {
  applicationsMap: { [id: string]: ApplicationDetails };
}

const initialState: ApplicationDetailsPageState = {
  applicationsMap: {},
};

export const applicationDetailsPageSlice = createSlice({
  name: "applicationDetails",
  initialState,
  reducers: {
    setApplicationDetailsFromApplication: (
      state,
      action: PayloadAction<{
        application: Application;
        openedFromApplicationsList: boolean;
      }>
    ) => {
      const currnetApplicationsDetails = state.applicationsMap[action.payload.application.id];
      if (currnetApplicationsDetails) {
        state.applicationsMap[action.payload.application.id].application = action.payload.application;
        state.applicationsMap[action.payload.application.id].openedFromApplicationsList = action.payload.openedFromApplicationsList;
      } else {
        state.applicationsMap[action.payload.application.id] = {
          application: action.payload.application,
          openedFromApplicationsList: action.payload.openedFromApplicationsList,
        };
      }
    },
    setApplicationDetails: (state, action: PayloadAction<ApplicationDetails>) => {
      state.applicationsMap[action.payload.application.id] = action.payload;
    },
    updateApplication: (state, action: PayloadAction<Application>) => {
      if (state.applicationsMap[action.payload.id]) {
        state.applicationsMap[action.payload.id].application = action.payload;
      }
    },
    updateResumeThumbnail: (
      state,
      action: PayloadAction<{
        applicationId: string;
        resumeThumbnail: string;
      }>
    ) => {
      if (state.applicationsMap[action.payload.applicationId]) {
        return {
          ...state,
          applicationsMap: {
            ...state.applicationsMap,
            [action.payload.applicationId]: {
              ...state.applicationsMap[action.payload.applicationId],
              resumeThumbnail: action.payload.resumeThumbnail,
            },
          },
        };
      }
    },
    updateCoverLetterThumbnail: (
      state,
      action: PayloadAction<{
        applicationId: string;
        coverLetterThumbnail: string;
      }>
    ) => {
      if (state.applicationsMap[action.payload.applicationId]) {
        return {
          ...state,
          applicationsMap: {
            ...state.applicationsMap,
            [action.payload.applicationId]: {
              ...state.applicationsMap[action.payload.applicationId],
              coverLetterThumbnail: action.payload.coverLetterThumbnail,
            },
          },
        };
      }
    },
    setPendingApplication: (
      state,
      action: PayloadAction<{
        applicationId: string;
        pendingApplication: Application | undefined;
      }>
    ) => {
      const { applicationId, pendingApplication } = action.payload;
      const application = state.applicationsMap[applicationId];
      if (application) {
        state.applicationsMap[applicationId].pendingApplication = pendingApplication;
      } else {
        console.error(`No application found with ID ${applicationId}`);
      }
    },
    setApplicationFromPending: (state, action: PayloadAction<string>) => {
      const applicationId = action.payload;
      const pendingApplication = state.applicationsMap[applicationId].pendingApplication;
      if (pendingApplication) {
        state.applicationsMap[applicationId].application = pendingApplication;
        state.applicationsMap[applicationId].pendingApplication = undefined;
      } else {
        console.error(`No application found with ID ${applicationId}`);
      }
    },
    clearApplication: (state, action: PayloadAction<string>) => {
      delete state.applicationsMap[action.payload];
    },
    clearApplicationDetailsPage: (state) => {
      state.applicationsMap = {};
    },
  },
});

export const {
  setApplicationDetailsFromApplication,
  updateApplication,
  updateResumeThumbnail,
  updateCoverLetterThumbnail,
  setApplicationDetails,
  setPendingApplication,
  setApplicationFromPending,
  clearApplication,
  clearApplicationDetailsPage,
} = applicationDetailsPageSlice.actions;
export const applicantDetailsPage = (state: RootState) => state.applicationDetailsPage;
export default applicationDetailsPageSlice.reducer;
