import store from "../store";
import { clearLoggedInUser } from "../reducers/userSlice";
import { clearApplicationPage } from "../reducers/applicationPageSlice";
import { clearProfilePage } from "../reducers/profilePageSlice";
import { clearApplicationDetailsPage } from "../reducers/applicantDetilsPageSlice";
import { clearApplicationsListPage } from "../reducers/applicationsListPageSlice";

export function logout() {
  const dispatch = store.dispatch;
  dispatch(clearLoggedInUser());
  dispatch(clearProfilePage());
  dispatch(clearApplicationPage());
  dispatch(clearApplicationDetailsPage());
  dispatch(clearApplicationsListPage());
}
