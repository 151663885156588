import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { WorkExperience } from "../types/WorkExperience";
import { Education } from "../types/Education";
import { Language } from "../types/Language";
import { Skill } from "../types/Skill";
import { ApplicantSupportInfo } from "../types/ApplicationSupportInfo";
import { SelfAssessmentQuestion } from "../types/SelfAssessment";

export interface ProfilePageState {
  workExperiences?: WorkExperience[];
  educations?: Education[];
  skills?: Skill[];
  languages?: Language[];
  supportInfo?: ApplicantSupportInfo | null | undefined;
  selfAssessmentQuestions?: SelfAssessmentQuestion[];
}

const initialState: ProfilePageState = {
  workExperiences: undefined,
  educations: undefined,
  skills: undefined,
  languages: undefined,
  selfAssessmentQuestions: undefined,
  supportInfo: undefined,
};

export const profilePageSlice = createSlice({
  name: "profilePage",
  initialState,
  reducers: {
    addWorkExperience: (state, action: PayloadAction<WorkExperience>) => {
      if (!state.workExperiences) {
        state.workExperiences = [];
      }
      state.workExperiences.push(action.payload);
    },
    addEducation: (state, action: PayloadAction<Education>) => {
      if (!state.educations) {
        state.educations = [];
      }
      state.educations.push(action.payload);
    },
    addSkill: (state, action: PayloadAction<Skill>) => {
      if (!state.skills) {
        state.skills = [];
      }
      state.skills.push(action.payload);
    },
    addLanguage: (state, action: PayloadAction<Language>) => {
      if (!state.languages) {
        state.languages = [];
      }
      state.languages.push(action.payload);
    },
    updateWorkExperience: (state, action: PayloadAction<WorkExperience>) => {
      if (state.workExperiences) {
        const index = state.workExperiences.findIndex((workExperience) => workExperience.id === action.payload.id);
        if (index !== -1) {
          state.workExperiences[index] = action.payload;
        }
      }
    },
    updateEducation: (state, action: PayloadAction<Education>) => {
      if (state.educations) {
        const index = state.educations.findIndex((education) => education.id === action.payload.id);
        if (index !== -1) {
          state.educations[index] = action.payload;
        }
      }
    },
    updateSkill: (state, action: PayloadAction<Skill>) => {
      if (state.skills) {
        const index = state.skills.findIndex((skill) => skill.id === action.payload.id);
        if (index !== -1) {
          state.skills[index] = action.payload;
        }
      }
    },
    updateLanguage: (state, action: PayloadAction<Language>) => {
      if (state.languages) {
        const index = state.languages.findIndex((language) => language.id === action.payload.id);
        if (index !== -1) {
          state.languages[index] = action.payload;
        }
      }
    },
    setProfilePageData: (state, action: PayloadAction<ProfilePageState>) => {
      state.workExperiences = action.payload.workExperiences;
      state.educations = action.payload.educations;
      state.skills = action.payload.skills;
      state.languages = action.payload.languages;
      state.selfAssessmentQuestions = action.payload.selfAssessmentQuestions;
      state.supportInfo = action.payload.supportInfo;
    },
    setWorkExperiences: (state, action: PayloadAction<WorkExperience[]>) => {
      state.workExperiences = action.payload;
    },
    setEducations: (state, action: PayloadAction<Education[]>) => {
      state.educations = action.payload;
    },
    setSkills: (state, action: PayloadAction<Skill[]>) => {
      state.skills = action.payload;
    },
    setLanguages: (state, action: PayloadAction<Language[]>) => {
      state.languages = action.payload;
    },
    setSupportInfo: (state, action: PayloadAction<ApplicantSupportInfo>) => {
      state.supportInfo = action.payload;
    },
    setSelfAssessmentQuestions: (state, action: PayloadAction<SelfAssessmentQuestion[]>) => {
      state.selfAssessmentQuestions = action.payload;
    },
    setSelfAssessmentQuestionAnswer: (
      state,
      action: PayloadAction<{
        questionId: string;
        answer: string;
      }>
    ) => {
      if (state.selfAssessmentQuestions) {
        const questionIndex = state.selfAssessmentQuestions.findIndex((question) => question.id === action.payload.questionId);
        if (questionIndex !== -1) {
          state.selfAssessmentQuestions[questionIndex].answer = action.payload.answer;
        }
      }
    },
    deleteWorkExperience: (state, action: PayloadAction<string>) => {
      if (state.workExperiences) {
        state.workExperiences = state.workExperiences.filter((experience) => experience.id !== action.payload);
      }
    },
    deleteEducation: (state, action: PayloadAction<string>) => {
      if (state.educations) {
        state.educations = state.educations.filter((education) => education.id !== action.payload);
      }
    },
    deleteSkill: (state, action: PayloadAction<string>) => {
      if (state.skills) {
        state.skills = state.skills.filter((skill) => skill.id !== action.payload);
      }
    },
    deleteLanguage: (state, action: PayloadAction<string>) => {
      if (state.languages) {
        state.languages = state.languages.filter((language) => language.id !== action.payload);
      }
    },
    clearProfilePage: (state) => {
      state.workExperiences = undefined;
      state.educations = undefined;
      state.skills = undefined;
      state.languages = undefined;
      state.supportInfo = undefined;
      state.selfAssessmentQuestions = undefined;
    },
  },
});

export const {
  addWorkExperience,
  addEducation,
  addSkill,
  addLanguage,
  updateWorkExperience,
  updateEducation,
  updateSkill,
  updateLanguage,
  clearProfilePage,
  setProfilePageData,
  setWorkExperiences,
  setEducations,
  setSkills,
  setLanguages,
  setSupportInfo,
  setSelfAssessmentQuestions,
  setSelfAssessmentQuestionAnswer,
  deleteWorkExperience,
  deleteEducation,
  deleteSkill,
  deleteLanguage,
} = profilePageSlice.actions;
export const profilePage = (state: RootState) => state.applicationPage;
export default profilePageSlice.reducer;
