import { Box, Typography, Button, CardProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { Application } from "../../../types/Application";
import { TFunction } from "i18next";
import notAppliedImage from "../../../assets/drawable/not_applied_image.jpg";
import appliedImage from "../../../assets/drawable/applied_image.jpg";
import { ExpandMore } from "@mui/icons-material";

export interface NudgeModuleProps extends CardProps {
  application: Application;
  openedFromApplicationsList: boolean;
  maxWidth: number;
  scrollToApplicationModule: () => void;
}

export const NudgeModule: React.FC<NudgeModuleProps> = ({
  application,
  openedFromApplicationsList,
  maxWidth,
  scrollToApplicationModule,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        mb: 6,
        maxWidth: maxWidth,
      }}
    >
      {renderContent(application, t, scrollToApplicationModule, openedFromApplicationsList)}
    </Box>
  );
};

const renderContent = (
  application: Application,
  t: TFunction,
  scrollToApplicationModule: () => void,
  openedFromApplicationsList: boolean
) => {
  if (application.hasApplied) {
    return renderAppliedContent(application, t);
  }

  if (application.hasSentToUser && openedFromApplicationsList) {
    return renderNotAppliedContent(application, t, scrollToApplicationModule);
  }

  return renderReadyToApplyContent(t);
};

const renderAppliedContent = (application: Application, t: TFunction) => (
  <>
    <Box sx={{ textAlign: "center", mb: 4 }}>
      <img src={appliedImage} alt="Applied" style={{ width: "250px", height: "auto" }} />
    </Box>
    <Typography variant="h4" gutterBottom>
      {t("applicationDetails.page.appliedTitle")}
    </Typography>
    <Typography variant="subtitle1">
      {t("applicationDetails.page.appliedBody", { workplace: application.workplace, role: application.role })}
    </Typography>
  </>
);

const renderNotAppliedContent = (application: Application, t: (key: string) => string, scrollToApplicationModule: () => void) => (
  <>
    <Box sx={{ textAlign: "center", mb: 4 }}>
      <img src={notAppliedImage} alt="Not Applied" style={{ width: "250px", height: "auto" }} />
    </Box>
    <Typography variant="h4" gutterBottom>
      {t("applicationDetails.page.notAppliedTitle")}
    </Typography>
    <Typography variant="subtitle1">{t("applicationDetails.page.notAppliedBody")}</Typography>
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Button variant="text" color="primary" onClick={scrollToApplicationModule} sx={{ mt: 2 }} endIcon={<ExpandMore />}>
        Scrolla ner
      </Button>
    </Box>
  </>
);

const renderReadyToApplyContent = (t: (key: string) => string) => (
  <>
    <Typography variant="h4" gutterBottom>
      {t("applicationDetails.page.yourApplicationIsReady")}
    </Typography>
    <Typography variant="subtitle1">{t("applicationDetails.page.downloadOrEmailNow")}</Typography>
  </>
);
