import { OccupationGroupFilter } from "../../../types/OccupationGroupFilter";
import { RegionFilter } from "../../../types/RegionFilter";

export const regionFilters: RegionFilter[] = [
  {
    id: "DQZd_uYs_oKb",
    name: "Blekinge län",
    municipalities: [
      {
        id: "HtGW_WgR_dpE",
        name: "Karlshamn",
      },
      {
        id: "YSt4_bAa_ccs",
        name: "Karlskrona",
      },
      {
        id: "1gEC_kvM_TXK",
        name: "Olofström",
      },
      {
        id: "vH8x_gVz_z7R",
        name: "Ronneby",
      },
      {
        id: "EVPy_phD_8Vf",
        name: "Sölvesborg",
      },
    ],
  },
  {
    id: "oDpK_oZ2_WYt",
    name: "Dalarnas län",
    municipalities: [
      {
        id: "Szbq_2fg_ydQ",
        name: "Avesta",
      },
      {
        id: "cpya_jJg_pGp",
        name: "Borlänge",
      },
      {
        id: "N1wJ_Cuu_7Cs",
        name: "Falun",
      },
      {
        id: "Nn7p_W3Z_y68",
        name: "Gagnef",
      },
      {
        id: "DE9u_V4K_Z1S",
        name: "Hedemora",
      },
      {
        id: "7Zsu_ant_gcn",
        name: "Leksand",
      },
      {
        id: "Ny2b_2bo_7EL",
        name: "Ludvika",
      },
      {
        id: "FPCd_poj_3tq",
        name: "Malung-Sälen",
      },
      {
        id: "UGcC_kYx_fTs",
        name: "Mora",
      },
      {
        id: "CRyF_5Jg_4ht",
        name: "Orsa",
      },
      {
        id: "Jy3D_2ux_dg8",
        name: "Rättvik",
      },
      {
        id: "5zZX_8FH_Sbq",
        name: "Smedjebacken",
      },
      {
        id: "c3Zx_jBf_CqF",
        name: "Säter",
      },
      {
        id: "4eS9_HX1_M7V",
        name: "Vansbro",
      },
      {
        id: "cZtt_qGo_oBr",
        name: "Älvdalen",
      },
    ],
  },
  {
    id: "K8iD_VQv_2BA",
    name: "Gotlands län",
    municipalities: [
      {
        id: "Ft9P_E8F_VLJ",
        name: "Gotland",
      },
    ],
  },
  {
    id: "zupA_8Nt_xcD",
    name: "Gävleborgs län",
    municipalities: [
      {
        id: "KxjG_ig5_exF",
        name: "Bollnäs",
      },
      {
        id: "qk8Y_2b6_82D",
        name: "Gävle",
      },
      {
        id: "yuNd_3bg_ttc",
        name: "Hofors",
      },
      {
        id: "Utks_mwF_axY",
        name: "Hudiksvall",
      },
      {
        id: "63iQ_V6F_REB",
        name: "Ljusdal",
      },
      {
        id: "fFeF_RCz_Tm5",
        name: "Nordanstig",
      },
      {
        id: "GEvW_wKy_A9H",
        name: "Ockelbo",
      },
      {
        id: "JPSe_mUQ_NDs",
        name: "Ovanåker",
      },
      {
        id: "BbdN_xLB_k6s",
        name: "Sandviken",
      },
      {
        id: "JauG_nz5_7mu",
        name: "Söderhamn",
      },
    ],
  },
  {
    id: "wjee_qH2_yb6",
    name: "Hallands län",
    municipalities: [
      {
        id: "qaJg_wMR_C8T",
        name: "Falkenberg",
      },
      {
        id: "kUQB_KdK_kAh",
        name: "Halmstad",
      },
      {
        id: "3XMe_nGt_RcU",
        name: "Hylte",
      },
      {
        id: "3JKV_KSK_x6z",
        name: "Kungsbacka",
      },
      {
        id: "c1iL_rqh_Zja",
        name: "Laholm",
      },
      {
        id: "AkUx_yAq_kGr",
        name: "Varberg",
      },
    ],
  },
  {
    id: "65Ms_7r1_RTG",
    name: "Jämtlands län",
    municipalities: [
      {
        id: "gRNJ_hVW_Gpg",
        name: "Berg",
      },
      {
        id: "eNSc_Nj1_CDP",
        name: "Bräcke",
      },
      {
        id: "j35Q_VKL_NiM",
        name: "Härjedalen",
      },
      {
        id: "yurW_aLE_4ga",
        name: "Krokom",
      },
      {
        id: "Voto_egJ_FZP",
        name: "Ragunda",
      },
      {
        id: "ppjq_Eci_Wz9",
        name: "Strömsund",
      },
      {
        id: "D7ax_CXP_6r1",
        name: "Åre",
      },
      {
        id: "Vt7P_856_WZS",
        name: "Östersund",
      },
    ],
  },
  {
    id: "MtbE_xWT_eMi",
    name: "Jönköpings län",
    municipalities: [
      {
        id: "y9HE_XD7_WaD",
        name: "Aneby",
      },
      {
        id: "VacK_WF6_XVg",
        name: "Eksjö",
      },
      {
        id: "cNQx_Yqi_83Q",
        name: "Gislaved",
      },
      {
        id: "91VR_Hxi_GN4",
        name: "Gnosjö",
      },
      {
        id: "9zQB_3vU_BQA",
        name: "Habo",
      },
      {
        id: "KURg_KJF_Lwc",
        name: "Jönköping",
      },
      {
        id: "smXg_BXp_jTW",
        name: "Mullsjö",
      },
      {
        id: "KfXT_ySA_do2",
        name: "Nässjö",
      },
      {
        id: "L1cX_MjM_y8W",
        name: "Sävsjö",
      },
      {
        id: "Namm_SpC_RPG",
        name: "Tranås",
      },
      {
        id: "zFup_umX_LVv",
        name: "Vaggeryd",
      },
      {
        id: "xJqx_SLC_415",
        name: "Vetlanda",
      },
      {
        id: "6bS8_fzf_xpW",
        name: "Värnamo",
      },
    ],
  },
  {
    id: "9QUH_2bb_6Np",
    name: "Kalmar län",
    municipalities: [
      {
        id: "LY9i_qNL_kXf",
        name: "Borgholm",
      },
      {
        id: "1koj_6Bg_8K6",
        name: "Emmaboda",
      },
      {
        id: "AEQD_1RT_vM9",
        name: "Hultsfred",
      },
      {
        id: "WPDh_pMr_RLZ",
        name: "Högsby",
      },
      {
        id: "Pnmg_SgP_uHQ",
        name: "Kalmar",
      },
      {
        id: "8eEp_iz4_cNN",
        name: "Mönsterås",
      },
      {
        id: "Muim_EAi_EFp",
        name: "Mörbylånga",
      },
      {
        id: "xk68_bJa_6Fh",
        name: "Nybro",
      },
      {
        id: "tUP8_hRE_NcF",
        name: "Oskarshamn",
      },
      {
        id: "wYFb_q7w_Nnh",
        name: "Torsås",
      },
      {
        id: "a7hJ_zwv_2FR",
        name: "Vimmerby",
      },
      {
        id: "t7H4_S2P_3Fw",
        name: "Västervik",
      },
    ],
  },
  {
    id: "tF3y_MF9_h5G",
    name: "Kronobergs län",
    municipalities: [
      {
        id: "MMph_wmN_esc",
        name: "Alvesta",
      },
      {
        id: "nXZy_1Jd_D8X",
        name: "Lessebo",
      },
      {
        id: "GzKo_S48_QCm",
        name: "Ljungby",
      },
      {
        id: "ZhVf_yL5_Q5g",
        name: "Markaryd",
      },
      {
        id: "qz8Q_kDz_N2Y",
        name: "Tingsryd",
      },
      {
        id: "78cu_S5T_Pgp",
        name: "Uppvidinge",
      },
      {
        id: "mmot_H3A_auW",
        name: "Växjö",
      },
      {
        id: "EK6X_wZq_CQ8",
        name: "Älmhult",
      },
    ],
  },
  {
    id: "9hXe_F4g_eTG",
    name: "Norrbottens län",
    municipalities: [
      {
        id: "vkQW_GB6_MNk",
        name: "Arjeplog",
      },
      {
        id: "A5WX_XVo_Zt6",
        name: "Arvidsjaur",
      },
      {
        id: "y4NQ_tnB_eVd",
        name: "Boden",
      },
      {
        id: "6R2u_zkb_uoS",
        name: "Gällivare",
      },
      {
        id: "tfRE_hXa_eq7",
        name: "Haparanda",
      },
      {
        id: "mp6j_2b6_1bz",
        name: "Jokkmokk",
      },
      {
        id: "cUyN_C9V_HLU",
        name: "Kalix",
      },
      {
        id: "biN6_UiL_Qob",
        name: "Kiruna",
      },
      {
        id: "CXbY_gui_14v",
        name: "Luleå",
      },
      {
        id: "dHMF_72G_4NM",
        name: "Pajala",
      },
      {
        id: "umej_bP2_PpK",
        name: "Piteå",
      },
      {
        id: "14WF_zh1_W3y",
        name: "Älvsbyn",
      },
      {
        id: "n5Sq_xxo_QWL",
        name: "Överkalix",
      },
      {
        id: "ehMP_onv_Chk",
        name: "Övertorneå",
      },
    ],
  },
  {
    id: "CaRE_1nn_cSU",
    name: "Skåne län",
    municipalities: [
      {
        id: "waQp_FjW_qhF",
        name: "Bjuv",
      },
      {
        id: "WMNK_PXa_Khm",
        name: "Bromölla",
      },
      {
        id: "64g5_Lio_aMU",
        name: "Burlöv",
      },
      {
        id: "i8vK_odq_6ar",
        name: "Båstad",
      },
      {
        id: "gfCw_egj_1M4",
        name: "Eslöv",
      },
      {
        id: "qj3q_oXH_MGR",
        name: "Helsingborg",
      },
      {
        id: "bP5q_53x_aqJ",
        name: "Hässleholm",
      },
      {
        id: "8QQ6_e95_a1d",
        name: "Höganäs",
      },
      {
        id: "autr_KMa_cfp",
        name: "Hörby",
      },
      {
        id: "N29z_AqQ_Ppc",
        name: "Höör",
      },
      {
        id: "JARU_FAY_hTS",
        name: "Klippan",
      },
      {
        id: "vrvW_sr8_1en",
        name: "Kristianstad",
      },
      {
        id: "5ohg_WJU_Ktn",
        name: "Kävlinge",
      },
      {
        id: "Yt5s_Vf9_rds",
        name: "Landskrona",
      },
      {
        id: "naG4_AUS_z2v",
        name: "Lomma",
      },
      {
        id: "muSY_tsR_vDZ",
        name: "Lund",
      },
      {
        id: "oYPt_yRA_Smm",
        name: "Malmö",
      },
      {
        id: "najS_Lvy_mDD",
        name: "Osby",
      },
      {
        id: "BN7r_iPV_F9p",
        name: "Perstorp",
      },
      {
        id: "dLxo_EpC_oPe",
        name: "Simrishamn",
      },
      {
        id: "P3Cs_1ZP_9XB",
        name: "Sjöbo",
      },
      {
        id: "oezL_78x_r89",
        name: "Skurup",
      },
      {
        id: "vBrj_bov_KEX",
        name: "Staffanstorp",
      },
      {
        id: "2r6J_g2w_qp5",
        name: "Svalöv",
      },
      {
        id: "n6r4_fjK_kRr",
        name: "Svedala",
      },
      {
        id: "UMev_wGs_9bg",
        name: "Tomelilla",
      },
      {
        id: "STvk_dra_M1X",
        name: "Trelleborg",
      },
      {
        id: "Tcog_5sH_b46",
        name: "Vellinge",
      },
      {
        id: "hdYk_hnP_uju",
        name: "Ystad",
      },
      {
        id: "pCuv_P5A_9oh",
        name: "Ängelholm",
      },
      {
        id: "tEv6_ktG_QQb",
        name: "Åstorp",
      },
      {
        id: "nBTS_Nge_dVH",
        name: "Örkelljunga",
      },
      {
        id: "LTt7_CGG_RUf",
        name: "Östra Göinge",
      },
    ],
  },
  {
    id: "CifL_Rzy_Mku",
    name: "Stockholms län",
    municipalities: [
      {
        id: "CCVZ_JA7_d3y",
        name: "Botkyrka",
      },
      {
        id: "E4CV_a5E_ucX",
        name: "Danderyd",
      },
      {
        id: "magF_Gon_YL2",
        name: "Ekerö",
      },
      {
        id: "Q7gp_9dT_k2F",
        name: "Haninge",
      },
      {
        id: "g1Gc_aXK_EKu",
        name: "Huddinge",
      },
      {
        id: "qm5H_jsD_fUF",
        name: "Järfälla",
      },
      {
        id: "FBbF_mda_TYD",
        name: "Lidingö",
      },
      {
        id: "aYA7_PpG_BqP",
        name: "Nacka",
      },
      {
        id: "btgf_fS7_sKG",
        name: "Norrtälje",
      },
      {
        id: "mBKv_q3B_SK8",
        name: "Nykvarn",
      },
      {
        id: "37UU_T7x_oxG",
        name: "Nynäshamn",
      },
      {
        id: "4KBw_CPU_VQv",
        name: "Salem",
      },
      {
        id: "8ryy_X54_xJj",
        name: "Sigtuna",
      },
      {
        id: "Z5Cq_SgB_dsB",
        name: "Sollentuna",
      },
      {
        id: "zHxw_uJZ_NJ8",
        name: "Solna",
      },
      {
        id: "AvNB_uwa_6n6",
        name: "Stockholm",
      },
      {
        id: "UTJZ_zHH_mJm",
        name: "Sundbyberg",
      },
      {
        id: "g6hK_M1o_hiU",
        name: "Södertälje",
      },
      {
        id: "sTPc_k2B_SqV",
        name: "Tyresö",
      },
      {
        id: "onpA_B5a_zfv",
        name: "Täby",
      },
      {
        id: "XWKY_c49_5nv",
        name: "Upplands Väsby",
      },
      {
        id: "w6yq_CGR_Fiv",
        name: "Upplands-Bro",
      },
      {
        id: "K4az_Bm6_hRV",
        name: "Vallentuna",
      },
      {
        id: "9aAJ_j6L_DST",
        name: "Vaxholm",
      },
      {
        id: "15nx_Vut_GrH",
        name: "Värmdö",
      },
      {
        id: "8gKt_ZsV_PGj",
        name: "Österåker",
      },
    ],
  },
  {
    id: "s93u_BEb_sx2",
    name: "Södermanlands län",
    municipalities: [
      {
        id: "kMxr_NiX_YrU",
        name: "Eskilstuna",
      },
      {
        id: "P8yp_WT9_Bks",
        name: "Flen",
      },
      {
        id: "os8Y_RUo_U3u",
        name: "Gnesta",
      },
      {
        id: "snx9_qVD_Dr1",
        name: "Katrineholm",
      },
      {
        id: "KzvD_ePV_DKQ",
        name: "Nyköping",
      },
      {
        id: "72XK_mUU_CAH",
        name: "Oxelösund",
      },
      {
        id: "shnD_RiE_RKL",
        name: "Strängnäs",
      },
      {
        id: "rjzu_nQn_mCK",
        name: "Trosa",
      },
      {
        id: "rut9_f5W_kTX",
        name: "Vingåker",
      },
    ],
  },
  {
    id: "zBon_eET_fFU",
    name: "Uppsala län",
    municipalities: [
      {
        id: "HGwg_unG_TsG",
        name: "Enköping",
      },
      {
        id: "sD2e_1Tr_4WZ",
        name: "Heby",
      },
      {
        id: "Bbs5_JUs_Qh5",
        name: "Håbo",
      },
      {
        id: "KALq_sG6_VrW",
        name: "Knivsta",
      },
      {
        id: "K8A2_JBa_e6e",
        name: "Tierp",
      },
      {
        id: "otaF_bQY_4ZD",
        name: "Uppsala",
      },
      {
        id: "cbyw_9aK_Cni",
        name: "Älvkarleby",
      },
      {
        id: "VE3L_3Ei_XbG",
        name: "Östhammar",
      },
    ],
  },
  {
    id: "EVVp_h6U_GSZ",
    name: "Värmlands län",
    municipalities: [
      {
        id: "yGue_F32_wev",
        name: "Arvika",
      },
      {
        id: "N5HQ_hfp_7Rm",
        name: "Eda",
      },
      {
        id: "UXir_vKD_FuW",
        name: "Filipstad",
      },
      {
        id: "xnEt_JN3_GkA",
        name: "Forshaga",
      },
      {
        id: "PSNt_P95_x6q",
        name: "Grums",
      },
      {
        id: "qk9a_g5U_sAH",
        name: "Hagfors",
      },
      {
        id: "x5qW_BXr_aut",
        name: "Hammarö",
      },
      {
        id: "hRDj_PoV_sFU",
        name: "Karlstad",
      },
      {
        id: "ocMw_Rz5_B1L",
        name: "Kil",
      },
      {
        id: "SVQS_uwJ_m2B",
        name: "Kristinehamn",
      },
      {
        id: "x73h_7rW_mXN",
        name: "Munkfors",
      },
      {
        id: "mPt5_3QD_LTM",
        name: "Storfors",
      },
      {
        id: "oqNH_cnJ_Tdi",
        name: "Sunne",
      },
      {
        id: "wmxQ_Guc_dsy",
        name: "Säffle",
      },
      {
        id: "hQdb_zn9_Sok",
        name: "Torsby",
      },
      {
        id: "ymBu_aFc_QJA",
        name: "Årjäng",
      },
    ],
  },
  {
    id: "g5Tt_CAV_zBd",
    name: "Västerbottens län",
    municipalities: [
      {
        id: "vQkf_tw2_CmR",
        name: "Bjurholm",
      },
      {
        id: "tSkf_Tbn_rHk",
        name: "Dorotea",
      },
      {
        id: "7rpN_naz_3Uz",
        name: "Lycksele",
      },
      {
        id: "7sHJ_YCE_5Zv",
        name: "Malå",
      },
      {
        id: "wMab_4Zs_wpM",
        name: "Nordmaling",
      },
      {
        id: "XmpG_vPQ_K7T",
        name: "Norsjö",
      },
      {
        id: "p8Mv_377_bxp",
        name: "Robertsfors",
      },
      {
        id: "kicB_LgH_2Dk",
        name: "Skellefteå",
      },
      {
        id: "VM7L_yJK_Doo",
        name: "Sorsele",
      },
      {
        id: "gQgT_BAk_fMu",
        name: "Storuman",
      },
      {
        id: "QiGt_BLu_amP",
        name: "Umeå",
      },
      {
        id: "tUnW_mFo_Hvi",
        name: "Vilhelmina",
      },
      {
        id: "izT6_zWu_tta",
        name: "Vindeln",
      },
      {
        id: "utQc_6xq_Dfm",
        name: "Vännäs",
      },
      {
        id: "xLdL_tMA_JJv",
        name: "Åsele",
      },
    ],
  },
  {
    id: "NvUF_SP1_1zo",
    name: "Västernorrlands län",
    municipalities: [
      {
        id: "uYRx_AdM_r4A",
        name: "Härnösand",
      },
      {
        id: "yR8g_7Jz_HBZ",
        name: "Kramfors",
      },
      {
        id: "v5y4_YPe_TMZ",
        name: "Sollefteå",
      },
      {
        id: "dJbx_FWY_tK6",
        name: "Sundsvall",
      },
      {
        id: "oJ8D_rq6_kjt",
        name: "Timrå",
      },
      {
        id: "swVa_cyS_EMN",
        name: "Ånge",
      },
      {
        id: "zBmE_n6s_MnQ",
        name: "Örnsköldsvik",
      },
    ],
  },
  {
    id: "G6DV_fKE_Viz",
    name: "Västmanlands län",
    municipalities: [
      {
        id: "Jkyb_5MQ_7pB",
        name: "Arboga",
      },
      {
        id: "7D9G_yrX_AGJ",
        name: "Fagersta",
      },
      {
        id: "oXYf_HmD_ddE",
        name: "Hallstahammar",
      },
      {
        id: "Fac5_h7a_UoM",
        name: "Kungsör",
      },
      {
        id: "4Taz_AuG_tSm",
        name: "Köping",
      },
      {
        id: "jbVe_Cps_vtd",
        name: "Norberg",
      },
      {
        id: "dAen_yTK_tqz",
        name: "Sala",
      },
      {
        id: "Nufj_vmt_VrH",
        name: "Skinnskatteberg",
      },
      {
        id: "jfD3_Hdg_UhT",
        name: "Surahammar",
      },
      {
        id: "8deT_FRF_2SP",
        name: "Västerås",
      },
    ],
  },
  {
    id: "zdoY_6u5_Krt",
    name: "Västra Götalands län",
    municipalities: [
      {
        id: "17Ug_Btv_mBr",
        name: "Ale",
      },
      {
        id: "UQ75_1eU_jaC",
        name: "Alingsås",
      },
      {
        id: "hejM_Jct_XJk",
        name: "Bengtsfors",
      },
      {
        id: "ypAQ_vTD_KLU",
        name: "Bollebygd",
      },
      {
        id: "TpRZ_bFL_jhL",
        name: "Borås",
      },
      {
        id: "NMc9_oEm_yxy",
        name: "Dals-Ed",
      },
      {
        id: "ZzEA_2Fg_Pt2",
        name: "Essunga",
      },
      {
        id: "ZySF_gif_zE4",
        name: "Falköping",
      },
      {
        id: "kCHb_icw_W5E",
        name: "Färgelanda",
      },
      {
        id: "ZNZy_Hh5_gSW",
        name: "Grästorp",
      },
      {
        id: "roiB_uVV_4Cj",
        name: "Gullspång",
      },
      {
        id: "PVZL_BQT_XtL",
        name: "Göteborg",
      },
      {
        id: "txzq_PQY_FGi",
        name: "Götene",
      },
      {
        id: "J116_VFs_cg6",
        name: "Herrljunga",
      },
      {
        id: "YbFS_34r_K2v",
        name: "Hjo",
      },
      {
        id: "dzWW_R3G_6Eh",
        name: "Härryda",
      },
      {
        id: "e413_94L_hdh",
        name: "Karlsborg",
      },
      {
        id: "ZkZf_HbK_Mcr",
        name: "Kungälv",
      },
      {
        id: "yHV7_2Y6_zQx",
        name: "Lerum",
      },
      {
        id: "FN1Y_asc_D8y",
        name: "Lidköping",
      },
      {
        id: "YQcE_SNB_Tv3",
        name: "Lilla Edet",
      },
      {
        id: "z2cX_rjC_zFo",
        name: "Lysekil",
      },
      {
        id: "Lzpu_thX_Wpa",
        name: "Mariestad",
      },
      {
        id: "7HAb_9or_eFM",
        name: "Mark",
      },
      {
        id: "tt1B_7rH_vhG",
        name: "Mellerud",
      },
      {
        id: "96Dh_3sQ_RFb",
        name: "Munkedal",
      },
      {
        id: "mc45_ki9_Bv3",
        name: "Mölndal",
      },
      {
        id: "tmAp_ykH_N6k",
        name: "Orust",
      },
      {
        id: "CCiR_sXa_BVW",
        name: "Partille",
      },
      {
        id: "k1SK_gxg_dW4",
        name: "Skara",
      },
      {
        id: "fqAy_4ji_Lz2",
        name: "Skövde",
      },
      {
        id: "aKkp_sEX_cVM",
        name: "Sotenäs",
      },
      {
        id: "wHrG_FBH_hoD",
        name: "Stenungsund",
      },
      {
        id: "PAxT_FLT_3Kq",
        name: "Strömstad",
      },
      {
        id: "rZWC_pXf_ySZ",
        name: "Svenljunga",
      },
      {
        id: "qffn_qY4_DLk",
        name: "Tanum",
      },
      {
        id: "aLFZ_NHw_atB",
        name: "Tibro",
      },
      {
        id: "Zsf5_vpP_Bs4",
        name: "Tidaholm",
      },
      {
        id: "TbL3_HmF_gnx",
        name: "Tjörn",
      },
      {
        id: "SEje_LdC_9qN",
        name: "Tranemo",
      },
      {
        id: "CSy8_41F_YvX",
        name: "Trollhättan",
      },
      {
        id: "a15F_gAH_pn6",
        name: "Töreboda",
      },
      {
        id: "xQc2_SzA_rHK",
        name: "Uddevalla",
      },
      {
        id: "an4a_8t2_Zpd",
        name: "Ulricehamn",
      },
      {
        id: "fbHM_yhA_BqS",
        name: "Vara",
      },
      {
        id: "THif_q6H_MjG",
        name: "Vänersborg",
      },
      {
        id: "NfFx_5jj_ogg",
        name: "Vårgårda",
      },
      {
        id: "M1UC_Cnf_r7g",
        name: "Åmål",
      },
      {
        id: "Zjiv_rhk_oJK",
        name: "Öckerö",
      },
    ],
  },
  {
    id: "xTCk_nT5_Zjm",
    name: "Örebro län",
    municipalities: [
      {
        id: "dbF7_Ecz_CWF",
        name: "Askersund",
      },
      {
        id: "pvzC_muj_rcq",
        name: "Degerfors",
      },
      {
        id: "Ak9V_rby_yYS",
        name: "Hallsberg",
      },
      {
        id: "sCbY_r36_xhs",
        name: "Hällefors",
      },
      {
        id: "wgJm_upX_z5W",
        name: "Karlskoga",
      },
      {
        id: "viCA_36P_pQp",
        name: "Kumla",
      },
      {
        id: "oYEQ_m8Q_unY",
        name: "Laxå",
      },
      {
        id: "yaHU_E7z_YnE",
        name: "Lekeberg",
      },
      {
        id: "JQE9_189_Ska",
        name: "Lindesberg",
      },
      {
        id: "eF2n_714_hSU",
        name: "Ljusnarsberg",
      },
      {
        id: "WFXN_hsU_gmx",
        name: "Nora",
      },
      {
        id: "kuMn_feU_hXx",
        name: "Örebro",
      },
    ],
  },
  {
    id: "oLT3_Q9p_3nn",
    name: "Östergötlands län",
    municipalities: [
      {
        id: "e5LB_m9V_TnT",
        name: "Boxholm",
      },
      {
        id: "dMFe_J6W_iJv",
        name: "Finspång",
      },
      {
        id: "U4XJ_hYF_FBA",
        name: "Kinda",
      },
      {
        id: "bm2x_1mr_Qhx",
        name: "Linköping",
      },
      {
        id: "stqv_JGB_x8A",
        name: "Mjölby",
      },
      {
        id: "E1MC_1uG_phm",
        name: "Motala",
      },
      {
        id: "SYty_Yho_JAF",
        name: "Norrköping",
      },
      {
        id: "Pcv9_yYh_Uw8",
        name: "Söderköping",
      },
      {
        id: "VcCU_Y86_eKU",
        name: "Vadstena",
      },
      {
        id: "Sb3D_iGB_aXu",
        name: "Valdemarsvik",
      },
      {
        id: "vRRz_nLT_vYv",
        name: "Ydre",
      },
      {
        id: "bFWo_FRJ_x2T",
        name: "Åtvidaberg",
      },
      {
        id: "Fu8g_29u_3xF",
        name: "Ödeshög",
      },
    ],
  },
];

export const occupationGroupFilters: OccupationGroupFilter[] = [
  {
    id: "X82t_awd_Qyc",
    name: "Administration, ekonomi, juridik",
    occupations: [
      {
        id: "q8wL_kdi_WaW",
        name: "Advokater",
      },
      {
        id: "DZVN_v5g_Fco",
        name: "Affärs- och företagsjurister",
      },
      {
        id: "fsnw_ZCu_v2U",
        name: "Arbetsförmedlare",
      },
      {
        id: "sXgV_QFg_vpb",
        name: "Arkeologer och specialister inom humaniora m.m.",
      },
      {
        id: "TQkt_eeK_eNp",
        name: "Arkiv- och biblioteksassistenter m.fl.",
      },
      {
        id: "MGk3_LvL_AU2",
        name: "Backofficepersonal m.fl.",
      },
      {
        id: "vCAb_uuo_ek5",
        name: "Chefssekreterare och VD-assistenter m.fl.",
      },
      {
        id: "Uw4n_UB2_RCW",
        name: "Controller",
      },
      {
        id: "qsJJ_EYo_fzA",
        name: "Domare",
      },
      {
        id: "zKvJ_GnC_Szx",
        name: "Domstols- och juristsekreterare m.fl.",
      },
      {
        id: "ij8k_EwC_zyB",
        name: "Ekonomiassistenter m.fl.",
      },
      {
        id: "pTr9_RBT_9ur",
        name: "Finansanalytiker och investeringsrådgivare m.fl.",
      },
      {
        id: "9dLz_BCK_oDA",
        name: "Försäkringssäljare och försäkringsrådgivare",
      },
      {
        id: "JfYV_ZGg_6rm",
        name: "Förvaltnings- och organisationsjurister",
      },
      {
        id: "H2Zj_eL9_x28",
        name: "Gruppledare för kontorspersonal",
      },
      {
        id: "k1Nx_auG_sNh",
        name: "Informatörer, kommunikatörer och PR-specialister",
      },
      {
        id: "JAFv_ZaP_tDG",
        name: "Inkasserare och pantlånare m.fl.",
      },
      {
        id: "ByrL_ANp_UwV",
        name: "Kontorsreceptionister",
      },
      {
        id: "Qo7p_wjf_gtv",
        name: "Lednings- och organisationsutvecklare",
      },
      {
        id: "L7eh_ihX_vM5",
        name: "Löne- och personaladministratörer",
      },
      {
        id: "2kLc_pto_DpV",
        name: "Medicinska sekreterare, vårdadministratörer m.fl.",
      },
      {
        id: "aQpg_E2T_cWq",
        name: "Mäklare inom finans",
      },
      {
        id: "WZCM_nfS_eAk",
        name: "Nationalekonomer och makroanalytiker m.fl.",
      },
      {
        id: "bjqk_F3A_5Hk",
        name: "Personal- och HR-specialister",
      },
      {
        id: "vPP6_rsw_dck",
        name: "Planerare och utredare m.fl.",
      },
      {
        id: "q81z_3oD_1qu",
        name: "Redovisningsekonomer",
      },
      {
        id: "cRmd_536_pT1",
        name: "Revisorer m.fl.",
      },
      {
        id: "Ru4W_zn2_LEz",
        name: "Skadereglerare och värderare",
      },
      {
        id: "4W6S_1kA_nrv",
        name: "Skattehandläggare",
      },
      {
        id: "ZQTR_hVC_QhJ",
        name: "Skolassistenter m.fl.",
      },
      {
        id: "ZQiZ_6Kd_D3a",
        name: "Socialförsäkringshandläggare",
      },
      {
        id: "jv1x_tpK_32p",
        name: "Statistiker",
      },
      {
        id: "4aPu_2nd_8X6",
        name: "Telefonister",
      },
      {
        id: "Bbkh_nrm_2J8",
        name: "Traders och fondförvaltare",
      },
      {
        id: "6aak_8eh_iwT",
        name: "Åklagare",
      },
      {
        id: "Y6yY_SuR_hVh",
        name: "Övriga ekonomer",
      },
      {
        id: "oEmP_NC1_sXE",
        name: "Övriga handläggare",
      },
      {
        id: "br6a_VVS_V3y",
        name: "Övriga jurister",
      },
      {
        id: "eQ4M_CNm_ozj",
        name: "Övriga kontorsassistenter och sekreterare",
      },
    ],
  },
  {
    id: "j7Cq_ZJe_GkT",
    name: "Bygg och anläggning",
    occupations: [
      {
        id: "bZ4J_riZ_zK6",
        name: "Anläggningsarbetare",
      },
      {
        id: "DPPw_4wa_AsH",
        name: "Anläggningsdykare",
      },
      {
        id: "3ZtN_hhA_SM7",
        name: "Anläggningsmaskinförare m.fl.",
      },
      {
        id: "w6ud_quG_dgh",
        name: "Arbetsledare inom bygg, anläggning och gruva",
      },
      {
        id: "q2Ge_zrQ_zJb",
        name: "Betongarbetare",
      },
      {
        id: "7zKm_4NW_eey",
        name: "Brunnsborrare m.fl.",
      },
      {
        id: "bXfo_4hb_SXx",
        name: "Byggnads- och ventilationsplåtslagare",
      },
      {
        id: "XeBP_nMe_pXx",
        name: "Civilingenjörsyrken inom bygg och anläggning",
      },
      {
        id: "V79C_DD2_3Gj",
        name: "Golvläggare",
      },
      {
        id: "iHrC_aRv_fVm",
        name: "Grovarbetare inom bygg och anläggning",
      },
      {
        id: "f71c_gjL_RDq",
        name: "Gruv- och stenbrottsarbetare",
      },
      {
        id: "thZP_oR7_WrY",
        name: "Ingenjörer och tekniker inom bygg och anläggning",
      },
      {
        id: "cGxX_R9c_kW1",
        name: "Isoleringsmontörer",
      },
      {
        id: "XYzP_5Tn_7Ep",
        name: "Kranförare m.fl.",
      },
      {
        id: "3UzE_P8A_9sS",
        name: "Kyl- och värmepumpstekniker m.fl.",
      },
      {
        id: "NnB3_eWH_auo",
        name: "Murare m.fl.",
      },
      {
        id: "QKvX_v7r_PNL",
        name: "Målare",
      },
      {
        id: "Y4j1_P17_wVA",
        name: "Ställningsbyggare",
      },
      {
        id: "3g5x_Tm7_8QH",
        name: "Takmontörer",
      },
      {
        id: "9mJN_VwW_Xjg",
        name: "Träarbetare, snickare m.fl.",
      },
      {
        id: "fLKb_bJ3_69p",
        name: "VVS-montörer m.fl.",
      },
      {
        id: "AD3U_cFH_rbe",
        name: "Övriga byggnads- och anläggningsarbetare",
      },
    ],
  },
  {
    id: "ozTo_2Th_Ssx",
    name: "Data/IT",
    occupations: [
      {
        id: "oFJv_PaX_w3s",
        name: "Drifttekniker, IT",
      },
      {
        id: "BbGL_oy7_Cig",
        name: "IT-arkitekter",
      },
      {
        id: "PbdD_8R8_3K5",
        name: "Mjukvaru- och systemutvecklare m.fl.",
      },
      {
        id: "EZBn_BpC_zRJ",
        name: "Operativ analytiker",
      },
      {
        id: "YZXj_FFF_vLv",
        name: "Spelutvecklare",
      },
      {
        id: "9fq8_Kv3_aCR",
        name: "Systemadministratörer",
      },
      {
        id: "PUFd_rDs_9yS",
        name: "Systemtestare och testledare",
      },
      {
        id: "8pAU_vFs_QCC",
        name: "Webbmaster och webbadministratörer",
      },
    ],
  },
  {
    id: "obP7_qPm_3rr",
    name: "Försäljning, inköp, marknadsföring",
    occupations: [
      {
        id: "MfGa_r6F_yYc",
        name: "Butikssäljare, fackhandel",
      },
      {
        id: "ZwJp_aEd_HCx",
        name: "Butikssäljare, dagligvaror",
      },
      {
        id: "hbmC_ikY_k5X",
        name: "Demonstratörer, utesäljare och butiksdemonstratörer",
      },
      {
        id: "a6Xw_GpD_nFZ",
        name: "Företagssäljare",
      },
      {
        id: "rwKk_kXT_VWF",
        name: "Inköpare",
      },
      {
        id: "RAnz_58W_fyX",
        name: "Kassapersonal och maskinoperatörer, bageri",
      },
      {
        id: "nWRo_n4A_53U",
        name: "Marknadsanalytiker och marknadsförare m.fl.",
      },
      {
        id: "UpCe_CJK_2jA",
        name: "Marknadsundersökare och intervjuare",
      },
      {
        id: "zXYK_aLB_vU2",
        name: "Reklamföretagare",
      },
      {
        id: "kjBa_xbA_TJ1",
        name: "Reklamutdelare och tidningsdistributörer",
      },
      {
        id: "mFzL_KWb_1A1",
        name: "Säljande butikschefer och avdelningschefer i butik",
      },
      {
        id: "dtyN_pZY_ycM",
        name: "Telefonförsäljare",
      },
      {
        id: "nGC7_6Ko_pjX",
        name: "Torg- och marknadsförsäljare",
      },
      {
        id: "wyXw_cRE_Nmv",
        name: "Övriga försäljare, detaljhandel",
      },
    ],
  },
  {
    id: "i2Id_ha5_pwb",
    name: "Hantverksyrken",
    occupations: [
      {
        id: "7BY7_42a_BrJ",
        name: "Bagare och konditorer",
      },
      {
        id: "esx8_EQK_CZ5",
        name: "Florister",
      },
      {
        id: "NNUv_UYJ_vf3",
        name: "Fotografer",
      },
      {
        id: "qV65_RvP_nxt",
        name: "Frisörer",
      },
      {
        id: "nxGJ_H4J_ejm",
        name: "Glasmästare",
      },
      {
        id: "NqSY_HH7_4pV",
        name: "Guldsmeder",
      },
      {
        id: "FniQ_QQ4_pW6",
        name: "Juvelerare",
      },
      {
        id: "LUW8_h5V_6XL",
        name: "Låssmeder",
      },
      {
        id: "GSi4_xwM_vd6",
        name: "Målare",
      },
      {
        id: "wERo_xUN_T7J",
        name: "Möbeltapetserare",
      },
      {
        id: "oLR8_U5K_cDo",
        name: "Målare",
      },
      {
        id: "A2BJ_JbQ_Fck",
        name: "Skomakare",
      },
      {
        id: "W83M_iMR_PdD",
        name: "Skräddare och ateljésömmerskor m.fl.",
      },
      {
        id: "UmdM_Mos_kvN",
        name: "Tapetserare",
      },
      {
        id: "hMYi_rNs_rK6",
        name: "Textilindustriarbetare",
      },
      {
        id: "dL48_FHL_UZb",
        name: "Växtodlare",
      },
      {
        id: "HEuM_7dx_AaX",
        name: "Övriga hantverkare",
      },
    ],
  },
  {
    id: "78Xh_oS8_JNe",
    name: "Hotell, restaurang, storhushåll",
    occupations: [
      {
        id: "XWaM_NxU_c7A",
        name: "Baristor",
      },
      {
        id: "wWaZ_rES_G8m",
        name: "Bartendrar",
      },
      {
        id: "99kq_fek_r5h",
        name: "Besöksbokare",
      },
      {
        id: "YrmZ_FYi_Jmz",
        name: "Biljettbokare",
      },
      {
        id: "G6WQ_JXe_Xz5",
        name: "Croupierer",
      },
      {
        id: "65y2_f4k_ZF5",
        name: "Kafé- och konditoribiträden",
      },
      {
        id: "wujK_x78_eVB",
        name: "Kallskänkor",
      },
      {
        id: "oZkt_hh3_NXM",
        name: "Kockar och kallskänkor",
      },
      {
        id: "v1PP_XYh_5iV",
        name: "Köks- och restaurangbiträden",
      },
      {
        id: "HQDU_NU4_1FT",
        name: "Matroser och jungmän",
      },
      {
        id: "nnhk_rN8_Jhy",
        name: "Pizzabagare",
      },
      {
        id: "i9PB_nDs_x7t",
        name: "Reseledare",
      },
      {
        id: "peQK_kmf_HMh",
        name: "Restaurang- och kökschefer",
      },
      {
        id: "9hGJ_eRQ_4VV",
        name: "Restaurangbiträden",
      },
      {
        id: "ujnm_QVG_AUK",
        name: "Servitörer",
      },
      {
        id: "YjB1_qKU_F8B",
        name: "Städare",
      },
      {
        id: "QLhP_XBV_fEG",
        name: "Trafikassistenter",
      },
      {
        id: "B9Ay_pGb_FoB",
        name: "Turistguider",
      },
      {
        id: "mBJo_bVz_JhH",
        name: "Värdshusvärdar",
      },
    ],
  },
  {
    id: "vUrQ_Mxb_Fq7",
    name: "Hälso- och sjukvård",
    occupations: [
      {
        id: "fW7E_e2M_2wV",
        name: "Apotekare",
      },
      {
        id: "fwjm_7PC_JxK",
        name: "Arbetsterapeuter",
      },
      {
        id: "dPku_sLX_4nT",
        name: "Audionomer och logopeder",
      },
      {
        id: "cndK_EbQ_5Zh",
        name: "Barnmorskor",
      },
      {
        id: "nTMz_U93_Lsf",
        name: "Beteendevetare m.fl.",
      },
      {
        id: "PYF5_BqZ_WLi",
        name: "Biomedicinska analytiker m.fl.",
      },
      {
        id: "DSCA_WyT_GbY",
        name: "Dietister",
      },
      {
        id: "hRaa_CJD_W7G",
        name: "Fysioterapeuter och sjukgymnaster",
      },
      {
        id: "XtHe_Gks_jDq",
        name: "Läkare",
      },
      {
        id: "QfVX_JDq_6By",
        name: "Medicinska sekreterare och vårdadministratörer",
      },
      {
        id: "GatD_p9w_7EF",
        name: "Optiker",
      },
      {
        id: "GBkX_9G3_3fY",
        name: "Psykologer",
      },
      {
        id: "ri1W_Yf7_1j9",
        name: "Receptarier",
      },
      {
        id: "gFub_q7r_5Rh",
        name: "Sjuksköterskor",
      },
      {
        id: "KJkg_1fX_h7D",
        name: "Skötare",
      },
      {
        id: "zKye_JNm_4J1",
        name: "Tandhygienister",
      },
      {
        id: "PE5T_CrR_k7k",
        name: "Tandsköterskor",
      },
      {
        id: "ojSG_2VB_rzh",
        name: "Tandtekniker",
      },
      {
        id: "q2gg_kay_63g",
        name: "Undersköterskor",
      },
    ],
  },
  {
    id: "KHFy_7Dh_2Xb",
    name: "Industriell tillverkning",
    occupations: [
      {
        id: "c5DZ_f6W_Pe1",
        name: "Automationsingenjörer och -tekniker",
      },
      {
        id: "aZDB_NiY_1f3",
        name: "CNC-operatörer",
      },
      {
        id: "6rTY_JGQ_AcY",
        name: "Driftmaskinister",
      },
      {
        id: "r5at_9NZ_j58",
        name: "Elmontörer",
      },
      {
        id: "P9yU_fMF_1Wc",
        name: "Fordonsmontörer",
      },
      {
        id: "J58A_XKu_VwY",
        name: "Industrielektriker",
      },
      {
        id: "woZi_3Jk_ZD1",
        name: "Industrirobotoperatörer",
      },
      {
        id: "8SYN_N3H_Bd6",
        name: "Industrisnickare",
      },
      {
        id: "VnND_uW5_zUG",
        name: "Kvalitetsbedömare, textil, skinn och läder",
      },
      {
        id: "Ej2e_4eG_1hP",
        name: "Kyl- och värmepumpstekniker",
      },
      {
        id: "ibBH_sPp_cWg",
        name: "Lackerare",
      },
      {
        id: "T9zi_XgL_7Cw",
        name: "Lastbilsmekaniker",
      },
      {
        id: "MSmU_4iU_znt",
        name: "Låssmeder",
      },
      {
        id: "axCB_RMy_3f2",
        name: "Maskinoperatörer, farmaceutiska produkter",
      },
      {
        id: "Hnsu_qoe_5Hk",
        name: "Maskinoperatörer, kött- och fiskberedningsindustri",
      },
      {
        id: "yKvL_4pQ_JBr",
        name: "Maskinoperatörer, mejeri",
      },
      {
        id: "hF7G_Mse_7Sh",
        name: "Montörer, el- och teleutrustning",
      },
      {
        id: "grAc_ySL_zUX",
        name: "Processoperatörer, papper",
      },
      {
        id: "yzjT_1A7_Eet",
        name: "Speditörer och transportmäklare",
      },
      {
        id: "5CQm_6gU_WPS",
        name: "Svetsare och gasskärare",
      },
      {
        id: "2rzR_YV1_vVf",
        name: "Tågvärdar",
      },
      {
        id: "ugMH_HXn_17x",
        name: "Underhållsmekaniker och maskinreparatörer",
      },
      {
        id: "FE4p_8Kp_T7w",
        name: "Verkstadsmekaniker",
      },
      {
        id: "9qY2_Szu_YDb",
        name: "Verktygsmakare",
      },
    ],
  },
  {
    id: "8sxL_iUn_2dN",
    name: "Installation, drift, underhåll",
    occupations: [
      {
        id: "AS2T_FXr_fiy",
        name: "Anläggningsarbetare",
      },
      {
        id: "GfQ1_Es4_yhP",
        name: "Bygg- och anläggningsförare",
      },
      {
        id: "LgEM_2Wh_29J",
        name: "Byggnads- och ventilationsplåtslagare",
      },
      {
        id: "h29F_zpg_1QW",
        name: "Drifttekniker, vattenrening",
      },
      {
        id: "pyWq_82Q_9Xa",
        name: "Elektronikreparatörer och kommunikationselektriker",
      },
      {
        id: "3bcv_y2x_2n2",
        name: "Fjärr- och kraftledningsreparatörer",
      },
      {
        id: "h2XM_FKv_FTh",
        name: "Glasmästare",
      },
      {
        id: "yWe5_KGd_v5k",
        name: "Grovplåtslagare",
      },
      {
        id: "hS5i_iyU_N5m",
        name: "Hissmontörer",
      },
      {
        id: "MCND_27T_xZj",
        name: "Installationselektriker",
      },
      {
        id: "pCFX_H2U_1e8",
        name: "Kyl- och värmepumpstekniker",
      },
      {
        id: "ytDu_Fsz_hH2",
        name: "Ställningsbyggare",
      },
      {
        id: "S5k6_h96_1xh",
        name: "VVS-montörer",
      },
    ],
  },
  {
    id: "zTxg_kds_1At",
    name: "Kropps- och skönhetsvård",
    occupations: [
      {
        id: "nmDW_MxN_9wb",
        name: "Frisörer",
      },
      {
        id: "c4AN_xrf_kFo",
        name: "Hudterapeuter",
      },
      {
        id: "mPPr_GTE_3D4",
        name: "Massörer och massageterapeuter",
      },
      {
        id: "9tDe_yvB_NQZ",
        name: "Nagelskulptörer",
      },
    ],
  },
  {
    id: "LzzQ_Rtd_BdA",
    name: "Kultur, media, design",
    occupations: [
      {
        id: "t6kH_b5V_X3u",
        name: "Bildkonstnärer",
      },
      {
        id: "jEsS_HWR_Nsr",
        name: "Författare",
      },
      {
        id: "gGJe_HtU_PeK",
        name: "Inredare, dekoratörer och scenografer",
      },
      {
        id: "yE7G_NjK_UpG",
        name: "Journalister m.fl.",
      },
      {
        id: "x3dT_ijG_LGf",
        name: "Museiintendenter",
      },
      {
        id: "TLng_mTk_NBt",
        name: "Musiker, sångare och kompositörer",
      },
      {
        id: "hZAw_JwF_mDD",
        name: "Reklamföretagare",
      },
      {
        id: "Aekm_XBi_8hv",
        name: "Textilkonstnärer",
      },
    ],
  },
  {
    id: "5MkS_uK3_xYo",
    name: "Pedagogiskt arbete",
    occupations: [
      {
        id: "5qD7_QdX_34C",
        name: "Barnskötare",
      },
      {
        id: "p5Am_tEt_tjC",
        name: "Elevassistenter",
      },
      {
        id: "wTkf_5mE_9Bb",
        name: "Fritidspedagoger",
      },
      {
        id: "Fg9H_nxD_Ut2",
        name: "Förskollärare",
      },
      {
        id: "kxDb_Cmx_u52",
        name: "Lärare i grundskolans tidigare år",
      },
      {
        id: "Gmyi_vcD_Kpe",
        name: "Lärare i grundskolans senare år",
      },
      {
        id: "s5WL_YJD_eDv",
        name: "Lärare i gymnasieskolan",
      },
      {
        id: "hMZx_qtG_HCE",
        name: "Lärare i yrkesämnen",
      },
      {
        id: "BMAj_TyP_5BR",
        name: "Modersmålslärare i grundskolan",
      },
      {
        id: "UxzF_4Pm_7z3",
        name: "Speciallärare och specialpedagoger m.fl.",
      },
      {
        id: "HD2J_ZpX_X8j",
        name: "Studie- och yrkesvägledare",
      },
      {
        id: "phZm_6zX_hBu",
        name: "Universitets- och högskolelärare",
      },
    ],
  },
  {
    id: "pGnz_nAw_Jyy",
    name: "Sanering och renhållning",
    occupations: [
      {
        id: "ZhVa_QhE_1z1",
        name: "Gatustädare",
      },
      {
        id: "ZomP_8h4_7Au",
        name: "Miljöarbetare/Renhållningsarbetare",
      },
      {
        id: "sd3c_dS7_1xP",
        name: "Saneringsarbetare",
      },
      {
        id: "TEBL_puE_9LB",
        name: "Städare",
      },
    ],
  },
  {
    id: "pwRW_c9T_FFh",
    name: "Säkerhetsarbete",
    occupations: [
      {
        id: "iHGe_fpa_HF4",
        name: "Bevaknings- och säkerhetspersonal",
      },
      {
        id: "uF7X_2AP_XnH",
        name: "Brandmän",
      },
      {
        id: "jhQo_XFT_4Wh",
        name: "Flygplatskontrollanter",
      },
      {
        id: "T7mf_qd2_HAr",
        name: "Kustbevakningstjänstemän",
      },
      {
        id: "szk8_1Fs_HN6",
        name: "Ordningsvakter",
      },
      {
        id: "xSkv_yDh_9wR",
        name: "Parkeringsvakter",
      },
      {
        id: "A4XY_nuQ_pW3",
        name: "Poliser",
      },
      {
        id: "nsuF_4eM_gXE",
        name: "Väktare",
      },
      {
        id: "uo8P_SsN_dGW",
        name: "Övrig bevaknings- och säkerhetspersonal",
      },
    ],
  },
  {
    id: "oc8a_9YX_2Um",
    name: "Tekniskt arbete",
    occupations: [
      {
        id: "hByp_cSd_9xG",
        name: "Automationsingenjörer och -tekniker",
      },
      {
        id: "n2Vi_7BG_1YZ",
        name: "Biomedicinska analytiker m.fl.",
      },
      {
        id: "kExL_T5H_zdj",
        name: "Civilingenjörsyrken inom bygg och anläggning",
      },
      {
        id: "ZWWu_5XB_SfD",
        name: "Civilingenjörsyrken inom kemi och kemiteknik",
      },
      {
        id: "arMc_h7n_JLC",
        name: "Civilingenjörsyrken inom elektroteknik",
      },
      {
        id: "9CpQ_CBy_Tp3",
        name: "Civilingenjörsyrken inom maskinteknik",
      },
      {
        id: "8RHx_uq9_sg5",
        name: "Flygtekniker",
      },
      {
        id: "dCeS_7cS_QMf",
        name: "Ingenjörer och tekniker inom bygg och anläggning",
      },
      {
        id: "bQ3k_EZV_4nU",
        name: "Ingenjörer och tekniker inom elektronik",
      },
      {
        id: "mPhU_wKs_LdX",
        name: "Ingenjörer och tekniker inom elektroteknik",
      },
      {
        id: "rxTh_fdf_1Uv",
        name: "Ingenjörer och tekniker inom gruvteknik och metallurgi",
      },
      {
        id: "KcLX_Src_p98",
        name: "Ingenjörer och tekniker inom kemi och kemiteknik",
      },
      {
        id: "ZoVA_qkS_HVc",
        name: "Ingenjörer och tekniker inom maskinteknik",
      },
      {
        id: "zLq8_URd_CWm",
        name: "Ingenjörer och tekniker inom metallurgi",
      },
      {
        id: "NzXa_PNV_GH5",
        name: "Installationselektriker",
      },
      {
        id: "y5d7_vni_j9W",
        name: "Lantmätare",
      },
      {
        id: "fekJ_7PD_DZm",
        name: "Maskinbefäl",
      },
      {
        id: "gTEX_9az_FSk",
        name: "VVS-ingenjörer",
      },
      {
        id: "nwrf_QS3_6Je",
        name: "Övriga ingenjörer och tekniker",
      },
    ],
  },
  {
    id: "AiX9_ynK_q4X",
    name: "Transport",
    occupations: [
      {
        id: "VSeE_iU5_FM4",
        name: "Bil-, taxi- och lastbilsförare",
      },
      {
        id: "K6ne_fWA_nsu",
        name: "Buss- och spårvagnsförare",
      },
      {
        id: "L4Me_H9Q_hCo",
        name: "Flygledare",
      },
      {
        id: "cNzD_Jsc_vF5",
        name: "Lokförare",
      },
      {
        id: "7vn2_rMd_eCT",
        name: "Maskinbefäl",
      },
      {
        id: "uMQ9_8ib_qvt",
        name: "Matroser och jungmän",
      },
      {
        id: "LmnQ_Qeq_C52",
        name: "Piloter",
      },
      {
        id: "BQBr_NyM_xQi",
        name: "Sjöbefäl",
      },
      {
        id: "BMC4_4Gm_1oV",
        name: "Transportledare och transportsamordnare",
      },
      {
        id: "yhg7_Yk3_Ywh",
        name: "Tågvärdar",
      },
    ],
  },
];
