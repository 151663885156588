import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { JobAdListItem } from "../types/JobAdListItem";
import { RegionFilter } from "../types/RegionFilter";
import { MunicipalityFilter } from "../types/MunicipalityFilter";
import { OccupationGroupFilter } from "../types/OccupationGroupFilter";
import { OccupationFilter } from "../types/OccupationFilter";
import { regionFilters } from "../pages/application/methods/PlatsbankenFIlterData";

interface ApplicationPageState {
  inputMethod?: number;
  searchQuery?: string;
  searchRegionFilter: RegionFilter[];
  searchMunicipalityFilter: MunicipalityFilter[];
  searchOccupationGroupFilter: OccupationGroupFilter[];
  searchOccupationFilter: OccupationFilter[];
  searchResults?: JobAdListItem[];
  searchTotal?: number;
  searchCurrentPage: number;
}

const initialState: ApplicationPageState = {
  searchCurrentPage: 1,
  searchRegionFilter: [],
  searchMunicipalityFilter: [],
  searchOccupationGroupFilter: [],
  searchOccupationFilter: [],
};

export const applicationPageSlice = createSlice({
  name: "applicationPage",
  initialState,
  reducers: {
    setInputMethod: (state, action: PayloadAction<number | undefined>) => {
      state.inputMethod = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string | undefined>) => {
      state.searchQuery = action.payload;
    },
    setSearchRegionFilter: (state, action: PayloadAction<RegionFilter[]>) => {
      state.searchRegionFilter = action.payload;
    },
    setSearchMunicipalityFilter: (state, action: PayloadAction<MunicipalityFilter[]>) => {
      state.searchMunicipalityFilter = action.payload;
    },
    setSearchOccupationGroupFilter: (state, action: PayloadAction<OccupationGroupFilter[]>) => {
      state.searchOccupationGroupFilter = action.payload;
    },
    setSearchOccupationFilter: (state, action: PayloadAction<OccupationFilter[]>) => {
      state.searchOccupationFilter = action.payload;
    },
    setSearchResults: (state, action: PayloadAction<JobAdListItem[] | undefined>) => {
      state.searchResults = action.payload;
    },
    setSearchTotal: (state, action: PayloadAction<number | undefined>) => {
      state.searchTotal = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.searchCurrentPage = action.payload;
    },
    clearFilters: (state) => {
      state.searchRegionFilter = [];
      state.searchMunicipalityFilter = [];
      state.searchOccupationGroupFilter = [];
      state.searchOccupationFilter = [];
    },
    clearApplicationPage: (state) => {
      state.inputMethod = undefined;
      state.searchQuery = undefined;
      state.searchRegionFilter = [];
      state.searchMunicipalityFilter = [];
      state.searchOccupationGroupFilter = [];
      state.searchOccupationFilter = [];
      state.searchResults = undefined;
      state.searchTotal = undefined;
      state.searchCurrentPage = 1;
    },
  },
});

export const {
  setInputMethod,
  setSearchQuery,
  setSearchRegionFilter,
  setSearchMunicipalityFilter,
  setSearchOccupationGroupFilter,
  setSearchOccupationFilter,
  setSearchResults,
  setSearchTotal,
  setCurrentPage,
  clearFilters,
  clearApplicationPage,
} = applicationPageSlice.actions;

export const selectApplicationPage = (state: RootState) => state.applicationPage;

export default applicationPageSlice.reducer;
