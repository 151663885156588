import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Slider } from "@mui/material";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { UpdateApplicantSupportInfo } from "./ProfilePageRepository";
import { updateSupportInfo as apiUpdateSupportInfo } from "./ProfilePageRepository";
import { setSupportInfo } from "../../reducers/profilePageSlice";
import { SupportInfoFieldType } from "./SupportInfoField";
import { DatePicker } from "@mui/x-date-pickers";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import { formatDayjsToString } from "../../utils/DateUtils";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/StringUtils";

interface SupportInfoModalProps {
  type: SupportInfoFieldType;
  open: boolean;
  onClose: () => void;
  getUpdatedSupportInfo: (value: any) => UpdateApplicantSupportInfo | undefined;
  initialValue?: any;
}

const SupportInfoModal: React.FC<SupportInfoModalProps> = ({ open, onClose, getUpdatedSupportInfo, type, initialValue = "" }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSave = async () => {
    try {
      const updatedSupportInfo = getUpdatedSupportInfo(value);
      if (!updatedSupportInfo) {
        return;
      }

      const apiUpdatedSupportInfo = await apiUpdateSupportInfo(updatedSupportInfo);
      dispatch(setSupportInfo(apiUpdatedSupportInfo));
      dispatch(showSnackbar({ message: t("profilePage.supportInfo.saveSuccess", { typeLabel: type }), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t(`profilePage.supportInfo.${type}.saveError`), severity: "error" }));
    } finally {
      onClose();
    }
  };

  const typeLabel = () => {
    switch (type) {
      case SupportInfoFieldType.Address:
        return t("profilePage.supportInfo.addressLabel");
      case SupportInfoFieldType.PhoneNumber:
        return t("profilePage.supportInfo.phoneNumberLabel");
      case SupportInfoFieldType.FirstPreferredJob:
        return t("profilePage.supportInfo.firstPreferredJobLabel");
      case SupportInfoFieldType.SecondPreferredJob:
        return t("profilePage.supportInfo.secondPreferredJobLabel");
      case SupportInfoFieldType.ThirdPreferredJob:
        return t("profilePage.supportInfo.thirdPreferredJobLabel");
      case SupportInfoFieldType.DesiredJobInFiveYears:
        return t("profilePage.supportInfo.desiredJobInFiveYearsLabel");
      case SupportInfoFieldType.WorkTime:
        return t("profilePage.supportInfo.workTimeLabel");
      case SupportInfoFieldType.UnemploymentOrSickLeaveDate:
        return t("profilePage.supportInfo.unemploymentOrSickLeaveDateLabel");
      default:
        return "";
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && (typeof value === "string" ? value.trim() : value.address.trim())) {
      handleSave();
      event.preventDefault();
    }
  };

  const renderAddressFields = () => (
    <>
      <TextField
        inputRef={inputRef}
        autoFocus
        margin="dense"
        label={capitalizeFirstLetter(t("profilePage.supportInfo.streetNameLabel"))}
        type="text"
        fullWidth
        value={value?.streetName}
        onChange={(e) => setValue({ ...value, streetName: e.target.value })}
        onKeyDown={handleKeyPress}
      />
      <TextField
        margin="dense"
        label={capitalizeFirstLetter(t("profilePage.supportInfo.zipCodeLabel"))}
        type="text"
        fullWidth
        value={value?.zipCode}
        onChange={(e) => setValue({ ...value, zipCode: e.target.value })}
        onKeyDown={handleKeyPress}
      />
      <TextField
        margin="dense"
        label={capitalizeFirstLetter(t("profilePage.supportInfo.cityLabel"))}
        type="text"
        fullWidth
        value={value?.city}
        onChange={(e) => setValue({ ...value, city: e.target.value })}
        onKeyDown={handleKeyPress}
      />
    </>
  );

  const renderSingleField = (label: string) => (
    <TextField
      inputRef={inputRef}
      autoFocus
      margin="dense"
      label={capitalizeFirstLetter(label)}
      type="text"
      fullWidth
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={handleKeyPress}
    />
  );

  const renderDatePicker = () => (
    <Box>
      <DatePicker
        sx={{ m: 2 }}
        inputRef={inputRef}
        autoFocus
        label={capitalizeFirstLetter(t("profilePage.supportInfo.unemploymentOrSickLeaveDateLabel"))}
        value={dayjs(value)}
        maxDate={dayjs()}
        format="YYYY-MM-DD"
        onChange={(date) => setValue(formatDayjsToString(date))}
      />
    </Box>
  );

  const renderWorkTimeSelector = () => {
    function valuetext(sliderValue: number) {
      if (sliderValue === 100) {
        return t("profilePage.supportInfo.fullTimeLabel");
      }
      return sliderValue + "%";
    }

    const handleChange = (event: Event, newValue: number | number[]) => {
      setValue(newValue as number);
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          pt: 12,
          ml: 5,
        }}
      >
        <Slider
          defaultValue={value}
          getAriaValueText={valuetext}
          step={5}
          marks
          min={0}
          max={100}
          valueLabelDisplay="auto"
          sx={{
            width: "100%",
          }}
          value={value}
          onChange={handleChange}
        />
        <Typography variant="subtitle1" sx={{ ml: 5, width: "160px" }}>
          {value === 100 ? t("profilePage.supportInfo.fullTimeLabel") : `${value}%`}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("profilePage.supportInfo.editTitle", { typeLabel: typeLabel() })}</DialogTitle>
      <DialogContent sx={{ width: "600px" }}>
        {type === SupportInfoFieldType.Address
          ? renderAddressFields()
          : type === SupportInfoFieldType.UnemploymentOrSickLeaveDate
          ? renderDatePicker()
          : type === SupportInfoFieldType.WorkTime
          ? renderWorkTimeSelector()
          : renderSingleField(typeLabel())}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("generic.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          {t("profilePage.supportInfo.saveChangesButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SupportInfoModal;
