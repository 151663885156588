import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";
import { addEducation, updateEducation } from "../../reducers/profilePageSlice";
import { submitEducation, updateEducation as apiUpdateEducation } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { Education } from "../../types/Education";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface EducationModalProps {
  initialEducation: Education | null;
  open: boolean;
  onClose: () => void;
}

const EducationModal: React.FC<EducationModalProps> = ({ open, onClose, initialEducation }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [subject, setSubject] = useState(initialEducation?.subject ?? "");
  const [provider, setProvider] = useState(initialEducation?.provider ?? "");
  const [category, setCategory] = useState(initialEducation?.category ?? "");
  const [fromDate, setFromDate] = useState(dayjs(initialEducation?.fromDate ?? ""));
  const [toDate, setToDate] = useState(dayjs(initialEducation?.toDate ?? ""));
  const [description, setDescription] = useState(initialEducation?.description ?? "");
  const [isEditMode, setIsEditMode] = useState(initialEducation !== null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleAddEducation = async (newEducation: Omit<Education, "id">) => {
    try {
      const newEducationData = await submitEducation(newEducation);
      dispatch(addEducation(newEducationData));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.education.addError"), severity: "error" }));
    }
  };

  const handleUpdateEducation = async (updatedEducation: Education) => {
    try {
      const updatedEducationData = await apiUpdateEducation(updatedEducation);
      dispatch(updateEducation(updatedEducationData));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.education.updateError"), severity: "error" }));
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" && subject.trim() && provider.trim() && category.trim() && dayjs(fromDate).isValid() && dayjs(toDate).isValid()) {
      handleSave();
      event.preventDefault();
    } else if (event.key === "Escape") {
      isEditMode ? onClose() : clearData();
    }
  };

  useEffect(() => {
    setSubject(initialEducation?.subject ?? "");
    setProvider(initialEducation?.provider ?? "");
    setCategory(initialEducation?.category ?? "");
    setFromDate(dayjs(initialEducation?.fromDate ?? ""));
    setToDate(dayjs(initialEducation?.toDate ?? ""));
    setDescription(initialEducation?.description ?? "");
    setIsEditMode(initialEducation !== null);
  }, [initialEducation]);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  }, [open]);

  const clearData = () => {
    setSubject("");
    setProvider("");
    setCategory("");
    setFromDate(dayjs(""));
    setToDate(dayjs(""));
    setDescription("");
    onClose();
  };

  const handleSave = () => {
    if (initialEducation) {
      handleUpdateEducation({
        id: initialEducation.id,
        subject,
        provider,
        category,
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        description,
      });
    } else {
      handleAddEducation({
        subject,
        provider,
        category,
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        description,
      });
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={isEditMode ? onClose : clearData} fullWidth={true}>
      <DialogTitle>{isEditMode ? t("profilePage.education.editTitle") : t("profilePage.education.addTitle")}</DialogTitle>
      <DialogContent>
        <TextField
          inputRef={inputRef}
          autoFocus
          margin="dense"
          label={t("profilePage.education.subjectLabel")}
          type="text"
          fullWidth
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <TextField
          margin="dense"
          label={t("profilePage.education.providerLabel")}
          type="text"
          fullWidth
          value={provider}
          onChange={(e) => setProvider(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <TextField
          margin="dense"
          label={t("profilePage.education.categoryLabel")}
          type="text"
          fullWidth
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          onKeyDown={handleKeyPress}
        />

        <Box sx={{ my: 4, display: "flex" }}>
          <DatePicker
            sx={{ mr: 2, flexGrow: 1 }}
            label={t("profilePage.education.fromDateLabel")}
            value={fromDate}
            onChange={(date) => setFromDate(date ?? dayjs(""))}
            maxDate={dayjs()}
            format="YYYY-MM-DD"
          />

          <DatePicker
            sx={{ flexGrow: 1 }}
            label={t("profilePage.education.toDateLabel")}
            value={toDate}
            minDate={fromDate}
            format="YYYY-MM-DD"
            onChange={(date) => setToDate(date ?? dayjs(""))}
          />
        </Box>

        <TextField
          margin="dense"
          label={t("profilePage.education.descriptionLabel")}
          type="text"
          fullWidth
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={isEditMode ? onClose : clearData} color="primary">
          {t("generic.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained" disabled={!subject.trim() || !provider.trim() || !category.trim() || !dayjs(fromDate).isValid() || !dayjs(toDate).isValid()}>
          {isEditMode ? t("profilePage.education.saveChangesButton") : t("profilePage.education.addButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EducationModal;
