import axiosInstance from "../../utils/AxiosInstance";
import { WorkExperience } from "../../types/WorkExperience";
import { Education } from "../../types/Education";
import { Language } from "../../types/Language";
import { Skill } from "../../types/Skill";
import { ApplicantSupportInfo } from "../../types/ApplicationSupportInfo";
import { SelfAssessmentQuestion } from "../../types/SelfAssessment";

export function submitWorkExperience(data: CreateWorkExperience): Promise<WorkExperience> {
  return axiosInstance.post<WorkExperience>("/work-experiences", data).then((response) => response.data);
}

export function updateWorkExperience(data: UpdateWorkExperience): Promise<WorkExperience> {
  return axiosInstance.put<WorkExperience>("/work-experiences", data).then((response) => response.data);
}

export async function fetchWorkExperiences(): Promise<WorkExperience[]> {
  try {
    const response = await axiosInstance.get("/work-experiences");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch work experiences:", error);
    return [];
  }
}

export function deleteWorkExperience(id: string): Promise<void> {
  return axiosInstance.delete("/work-experiences", { data: { id } }).then((response) => response.data);
}

export function submitEducation(data: CreateEducation): Promise<Education> {
  return axiosInstance.post<Education>("/educations", data).then((response) => response.data);
}

export function updateEducation(data: UpdateEducation): Promise<Education> {
  return axiosInstance.put<Education>("/educations", data).then((response) => response.data);
}

export async function fetchEducations(): Promise<Education[]> {
  try {
    const response = await axiosInstance.get("/educations");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch educations:", error);
    return [];
  }
}

export function deleteEducation(id: string): Promise<void> {
  return axiosInstance.delete("/educations", { data: { id } }).then((response) => response.data);
}

export function submitSkill(data: CreateSkill): Promise<Skill> {
  return axiosInstance.post<Skill>("/skills", data).then((response) => response.data);
}

export function updateSkill(data: UpdateSkill): Promise<Skill> {
  return axiosInstance.put<Skill>("/skills", data).then((response) => response.data);
}

export async function fetchSkills(): Promise<Skill[]> {
  try {
    const response = await axiosInstance.get("/skills");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch skills:", error);
    return [];
  }
}

export function deleteSkill(id: string): Promise<void> {
  return axiosInstance.delete("/skills", { data: { id } }).then((response) => response.data);
}

export function submitLanguage(data: CreateLanguage): Promise<Language> {
  return axiosInstance.post<Language>("/languages", data).then((response) => response.data);
}

export function updateLanguage(data: UpdateLanguage): Promise<Language> {
  return axiosInstance.put<Language>("/languages", data).then((response) => response.data);
}

export async function fetchLanguages(): Promise<Language[]> {
  try {
    const response = await axiosInstance.get("/languages");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch languages:", error);
    return [];
  }
}

export function deleteLanguage(id: string): Promise<void> {
  return axiosInstance.delete("/languages", { data: { id } }).then((response) => response.data);
}

export async function updateSupportInfo(data: UpdateApplicantSupportInfo): Promise<ApplicantSupportInfo> {
  const response = await axiosInstance.put("/support_info", data);
  return response.data;
}

export async function fetchSupportInfo(): Promise<ApplicantSupportInfo | null> {
  try {
    const response = await axiosInstance.get("/support_info");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch support info:", error);
    return null;
  }
}

export async function updateProfilePicture(data: string): Promise<string> {
  const response = await axiosInstance.post("/profile_picture", {
    profile_picture: data,
  });
  return response.data.profileIconUrl;
}

export async function fetchSelfAssessment(): Promise<SelfAssessmentQuestion[]> {
  try {
    const response = await axiosInstance.get<SelfAssessmentQuestion[]>("/self_assessment");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch self assessment:", error);
    throw error;
  }
}

export async function postSelfAssessmentAnswer(data: PostSelfAssessmentAnswer): Promise<void> {
  try {
    await axiosInstance.post<{ message: string }>("/self_assessment", data);
  } catch (error) {
    console.error("Failed to post self assessment answer:", error);
    throw error;
  }
}

export async function fetchGeneratedIntroduction(): Promise<string> {
  try {
    const response = await axiosInstance.get<{ description: string }>("/generate_introduction");
    return response.data.description;
  } catch (error) {
    console.error("Failed to fetch generated introduction:", error);
    throw error;
  }
}

export type CreateWorkExperience = {
  workplace: string;
  position: string;
  fromDate: string;
  toDate: string | null;
  description: string | null;
};

export type UpdateWorkExperience = {
  id: string;
  workplace: string;
  position: string;
  fromDate: string;
  toDate: string | null;
  description: string | null;
};

export type CreateEducation = {
  subject: string;
  provider: string | null;
  fromDate: string;
  toDate: string | null;
  description: string | null;
};

export type UpdateEducation = {
  id: string;
  subject: string;
  provider: string | null;
  fromDate: string;
  toDate: string | null;
  description: string | null;
};

export interface UpdateApplicantSupportInfo {
  hasWorkLimitations: boolean | null;
  canTakeWorkImmediately: boolean | null;
  canWorkDaytime: boolean | null;
  canWorkEvening: boolean | null;
  canWorkNighttime: boolean | null;
  partTimePercentage?: number | null;
  unemploymentOrSickLeaveDate?: string | null;
  sfiLevel: string | null;
  educationLevel: string | null;
  firstPreferredJob?: string | null;
  secondPreferredJob?: string | null;
  thirdPreferredJob?: string | null;
  desiredJobInFiveYears?: string | null;
  description?: string | null;
  streetName?: string | null;
  zipCode?: string | null;
  city?: string | null;
  phoneNumber?: string | null;
  profileIconUrl?: string | null;
}

export type CreateSkill = {
  name: string;
};

export type UpdateSkill = {
  id: string;
  name: string;
};

export type CreateLanguage = {
  name: string;
};

export type UpdateLanguage = {
  id: string;
  name: string;
};

export interface PostSelfAssessmentAnswer {
  questionId: string;
  answer: string;
}
