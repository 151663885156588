import React from "react";
import { Card, CardActionArea, CardContent, Typography, Box } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ManualEntryForm from "./ManualEntryForm";
import JobAdLinkForm from "./JobAdLinkForm";
import JobListAdSearchForm from "./JobListAdSearchForm";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setInputMethod } from "../../../reducers/applicationPageSlice";
import { useTranslation } from "react-i18next";

export interface ApplicationMethodProps {
  handleGenerateManualApplication: (workplace: string, role: string, description: string) => void;
  handleGenerateJobAdApplication: (link: string) => void;
  handleGenerateJobListAdApplication: (jobAdId: string) => void;
}

type Method = {
  id: number;
  title: string;
  description: string;
  Icon: React.ElementType;
};

const ApplicationMethod: React.FC<ApplicationMethodProps> = (props: ApplicationMethodProps) => {
  const methods: Method[] = [
    { id: 1, title: "pickFromList.title", description: "pickFromList.description", Icon: ManageSearchIcon },
    { id: 2, title: "useJobAdLink.title", description: "useJobAdLink.description", Icon: InsertLinkIcon },
    { id: 3, title: "manualEntry.title", description: "manualEntry.description", Icon: EditNoteIcon },
  ];

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedMethod = useAppSelector((state) => state.applicationPage.inputMethod);

  const handleSelectMethod = (id: number) => {
    dispatch(setInputMethod(id));
  };

  const renderInputArea = () => {
    if (!selectedMethod) return null;

    switch (selectedMethod) {
      case 1:
        return <JobListAdSearchForm handleGenerateJobListAdApplication={props.handleGenerateJobListAdApplication} />;
      case 2:
        return <JobAdLinkForm handleGenerateJobAdApplication={props.handleGenerateJobAdApplication}></JobAdLinkForm>;
      case 3:
        return <ManualEntryForm handleGenerateManualApplication={props.handleGenerateManualApplication} />;
      default:
        return <Typography>{t("application.method.chooseMethodAbove")}</Typography>;
    }
  };

  return (
    <Box sx={{ maxWidth: 800, margin: "auto", textAlign: "center", padding: 8 }}>
      <Typography variant="h3" component="h1" sx={{ mb: 2 }}>
        {t("application.method.title")}
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 8 }}>
        {t("application.method.subtitle")}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 4 }}>
        {methods.map((method, index) => (
          <Card
            sx={{
              ml: index === 0 ? 0 : 2,
              mr: index === methods.length - 1 ? 0 : 2,
              width: "400px",
              borderRadius: 10,
            }}
            key={method.id}
            onClick={() => handleSelectMethod(method.id)}
          >
            <CardActionArea sx={{ height: "100%" }}>
              <CardContent>
                <method.Icon sx={{ fontSize: 60, margin: "20px auto", display: "block" }} />
                <Typography gutterBottom variant="h5" component="div">
                  {t(`application.method.methods.${method.title}`)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t(`application.method.methods.${method.description}`)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Box>
      <Box>{renderInputArea()}</Box>
    </Box>
  );
};

export default ApplicationMethod;
