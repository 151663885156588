import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MaijaSnackbar from "./components/MaijaSnackbar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FORGOT_PASSWORD_ROUTE, LOGIN_ROUTE, maijaRoutes, PROFILE_ROUTE } from "./Routes";
import LoginPage from "./pages/login/LoginPage";
import ProtectedRoute from "./components/ProtectedRoute";
import store from "./store";
import { Provider } from "react-redux";
import ProfilePage from "./pages/profile/ProfilePage";
import ForgotPasswordPage from "./pages/forgot-password/ForgotPasswordPage";
import "./i18n";
import CustomThemeProvider from "./CustomThemeProvider"

function App() {

  return (
    <Provider store={store}>
      <CustomThemeProvider>
          <React.Fragment>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CssBaseline />
              <Router>
                <Routes>
                  <Route path={LOGIN_ROUTE.path} index element={<LoginPage />} />
                  <Route path={FORGOT_PASSWORD_ROUTE.path} element={<ForgotPasswordPage />} />

                  {maijaRoutes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<ProtectedRoute route={route}>{React.createElement(route.component)}</ProtectedRoute>}
                    />
                  ))}
                  <Route
                    path="*"
                    element={
                      <ProtectedRoute route={PROFILE_ROUTE}>
                        <ProfilePage />
                      </ProtectedRoute>
                    }
                  ></Route>
                </Routes>
                <MaijaSnackbar />
              </Router>
            </LocalizationProvider>
          </React.Fragment>
      </CustomThemeProvider>
    </Provider>
  );
}

export default App;
