import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import { RootState } from "../../store";
import { Delete, Edit } from "@mui/icons-material";
import { Education } from "../../types/Education";
import EducationModal from "./EducationModal";
import { deleteEducation as apiDeleteEducation } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { deleteEducation } from "../../reducers/profilePageSlice";
import { formatDateToString } from "../../utils/DateUtils";
import { useTranslation } from "react-i18next";

const EducationsComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const educations = useSelector((state: RootState) => state.profilePage.educations);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEducation, setCurrentEducation] = useState<Education | null>(null);

  const handleAddClick = () => {
    setCurrentEducation(null);
    setIsModalOpen(true);
  };

  const handleEditClick = (education: Education) => {
    setCurrentEducation(education);
    setIsModalOpen(true);
  };

  const onDelete = async (id: string) => {
    try {
      await apiDeleteEducation(id);
      dispatch(deleteEducation(id));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.education.deleteError"), severity: "error" }));
    }
  };

  return (
    <Box sx={{ textAlign: "center", p: 2, height: "100%" }}>
      {educations && educations.length > 0 ? (
        <EducationsList educations={educations} onAdd={handleAddClick} onEdit={handleEditClick} onDelete={onDelete} />
      ) : (
        <EmptyState onAdd={handleAddClick} />
      )}
      <EducationModal open={isModalOpen} onClose={() => setIsModalOpen(false)} initialEducation={currentEducation} />
    </Box>
  );
};

interface EducationsListProps {
  educations: Education[];
  onAdd: () => void;
  onEdit: (education: Education) => void;
  onDelete: (id: string) => void;
}

const EducationsList: React.FC<EducationsListProps> = ({ educations, onAdd, onEdit, onDelete }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Typography variant="h6" sx={{ fontWeight: "bold", alignSelf: "flex-start" }}>
        {t("profilePage.education.title")}
      </Typography>
      <List sx={{ flex: 1 }}>
        {educations.map((education, index) => (
          <ListItem key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", py: 0.5, pl: 0 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: -2 }}>
                {education.provider}
              </Typography>
              <ListItemText
                primary={`${education.subject}, ${education.provider}`}
                secondary={`${formatDateToString(education.fromDate)} - ${
                  education.toDate ? formatDateToString(education.toDate) : t("profilePage.education.present")
                }`}
              />
            </Box>
            <Box>
              <IconButton edge="end" aria-label="edit" sx={{ mr: 1 }} onClick={() => onEdit(education)}>
                <Edit />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => onDelete(education.id)}>
                <Delete />
              </IconButton>
            </Box>
          </ListItem>
        ))}
      </List>
      <Button
        sx={{ mt: 2, minWidth: "30%", alignSelf: "center", width: "auto" }}
        variant="contained"
        color="primary"
        disableRipple
        onClick={onAdd}
      >
        {t("profilePage.education.addButton")}
      </Button>
    </Box>
  );
};

interface EmptyStateProps {
  onAdd: () => void;
}

const EmptyState: React.FC<EmptyStateProps> = ({ onAdd }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
        p: 2,
      }}
    >
      <Typography variant="h6">{t("profilePage.education.emptyStateTitle")}</Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
        {t("profilePage.education.emptyStateMessage")}
      </Typography>
      <Button variant="contained" color="primary" onClick={onAdd} disableRipple>
        {t("profilePage.education.addButton")}
      </Button>
    </Box>
  );
};

export default EducationsComponent;
