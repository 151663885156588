import React, { useEffect, useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import MaijaModal from "../../components/MaijaModal";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/profile_complete.json";
import { APPLICATION_ROUTE } from "../../Routes";

interface CompletedProfileModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const CompletedProfileModal: React.FC<CompletedProfileModalProps> = ({ isOpen, handleClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigateToApplications = () => {
    navigate(APPLICATION_ROUTE.path);
    handleClose();
  };

  const lottieOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    initialSegment: [0, 46],
  };

  return (
    <MaijaModal isOpen={isOpen} handleClose={handleClose} sx={{ width: "60vw", maxWidth: "800px" }}>
      <Box sx={{ textAlign: "center" }}>
        <Lottie options={lottieOptions} height={200} width={200} speed={0.6} />
        <Typography variant="h6" component="h2">
          {t("profilePage.completedProfileModal.congratulations")}
        </Typography>
        <Typography sx={{ mt: 2 }}>{t("profilePage.completedProfileModal.profileCompletionMessage")}</Typography>
        <Box sx={{ display: "flex", flexDirection: "row", mt: 5, justifyContent: "center" }}>
          <Button variant="contained" color="primary" onClick={handleNavigateToApplications} sx={{ mr: 2 }}>
            {t("profilePage.completedProfileModal.goToApplications")}
          </Button>
          <Button color="primary" onClick={handleClose}>
            {t("generic.close")}
          </Button>
        </Box>
      </Box>
    </MaijaModal>
  );
};

export default CompletedProfileModal;
