import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import type { MaijaSnackbarProps } from "../components/MaijaSnackbar";

interface SnackbarState {
  snackbar?: MaijaSnackbarProps;
}

const initialState: SnackbarState = {};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackbar: (state, action: PayloadAction<MaijaSnackbarProps>) => {
      state.snackbar = action.payload;
    },
    hideSnackbar: (state) => {
      state.snackbar = undefined;
    },
  },
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;
export const snackbar = (state: RootState) => state.snackbar.snackbar;
export default snackbarSlice.reducer;
