import React, { useState } from "react";
import { Card, CardContent, TextField, Button, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
export interface ManualEntryFormProps {
  handleGenerateManualApplication: (workplace: string, role: string, description: string) => void;
}

const ManualEntryForm: React.FC<ManualEntryFormProps> = (props: ManualEntryFormProps) => {
  const { t } = useTranslation();
  const [company, setCompany] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const isButtonDisabled = company.trim() === "" || role.trim() === "";

  const handleGenerateApplication = () => {
    props.handleGenerateManualApplication(company, role, description);
  };

  return (
    <Card sx={{ margin: "auto", mb: 2, p: 3, maxWidth: 800, borderRadius: 5 }}>
      <CardContent>
        <Typography variant="h6" align="left" sx={{ mb: 4, mt: -2 }}>
          {t("application.manualEntry.title")}
        </Typography>
        <Typography variant="subtitle1" align="left" sx={{ mb: 2 }}>
          {t("application.manualEntry.subtitle")}
        </Typography>

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { mb: 2, width: "100%" },
            "& .MuiButton-root": { padding: "10px 20px" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            required
            id="company-field"
            label={t("application.manualEntry.companyLabel")}
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />

          <TextField
            required
            id="position-field"
            label={t("application.manualEntry.positionLabel")}
            value={role}
            onChange={(e) => setRole(e.target.value)}
          />

          <TextField
            id="description-field"
            label={t("application.manualEntry.descriptionLabel")}
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={isButtonDisabled}
            onClick={handleGenerateApplication}
            sx={{ mt: 6 }}
          >
            {t("application.manualEntry.generateButton")}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ManualEntryForm;
