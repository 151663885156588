import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { RootState } from "../../store";
import { updateProfilePicture } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import SupportInfoField, { SupportInfoFieldType } from "./SupportInfoField";
import { ApplicantSupportInfo } from "../../types/ApplicationSupportInfo";
import { setProfilePictureUrl } from "../../reducers/userSlice";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/StringUtils";

const SupportInfoComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const supportInfo = useSelector((state: RootState) => state.profilePage.supportInfo);
  const profilePictureUrl = useSelector((state: RootState) => state.user.user?.profileIconUrl);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result as string;
        try {
          const profileIconUrl = await updateProfilePicture(base64String);
          dispatch(setProfilePictureUrl(profileIconUrl));
        } catch (error) {
          dispatch(
            showSnackbar({
              message: t("profilePage.supportInfo.saveError", { typeLabel: t("profilePage.supportInfo.profilePictureLabel") }),
              severity: "error",
            })
          );
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box sx={{ textAlign: "center", height: "100%" }}>
      {supportInfo ? (
        <Box>
          <SupportInfoContent supportInfo={supportInfo} profilePictureUrl={profilePictureUrl} handleFileChange={handleFileChange} />
        </Box>
      ) : (
        <MaijaLoadingPage />
      )}
    </Box>
  );
};

interface SupportInfoContentProps {
  supportInfo: ApplicantSupportInfo;
  profilePictureUrl: string | null | undefined;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SupportInfoContent: React.FC<SupportInfoContentProps> = ({ supportInfo, profilePictureUrl, handleFileChange }) => {
  const { t } = useTranslation();

  const leadingTitle = (title: string) => {
    return (
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        sx={{
          mr: 2,
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          height: "100%",
          minWidth: "230px",
        }}
      >
        {capitalizeFirstLetter(title)}
      </Typography>
    );
  };

  return (
    <Box sx={{ textAlign: "left", p: 2, height: "100%" }}>
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 3 }}>
        {t("profilePage.supportInfo.workSituationLabel")}
      </Typography>

      <SupportInfoField
        type={SupportInfoFieldType.FirstPreferredJob}
        info={supportInfo.firstPreferredJob}
        getUpdatedSupportInfo={(value: any) => ({ ...supportInfo, firstPreferredJob: value })}
        leadingSlot={leadingTitle(t("profilePage.supportInfo.firstPreferredJobLabel"))}
      />

      <SupportInfoField
        type={SupportInfoFieldType.SecondPreferredJob}
        info={supportInfo.secondPreferredJob}
        getUpdatedSupportInfo={(value: any) => ({ ...supportInfo, secondPreferredJob: value })}
        leadingSlot={leadingTitle(t("profilePage.supportInfo.secondPreferredJobLabel"))}
      />

      <SupportInfoField
        type={SupportInfoFieldType.ThirdPreferredJob}
        info={supportInfo.thirdPreferredJob}
        getUpdatedSupportInfo={(value: any) => ({ ...supportInfo, thirdPreferredJob: value })}
        leadingSlot={leadingTitle(t("profilePage.supportInfo.thirdPreferredJobLabel"))}
      />

      <SupportInfoField
        type={SupportInfoFieldType.DesiredJobInFiveYears}
        info={supportInfo.desiredJobInFiveYears}
        getUpdatedSupportInfo={(value: any) => ({ ...supportInfo, desiredJobInFiveYears: value })}
        leadingSlot={leadingTitle(t("profilePage.supportInfo.desiredJobInFiveYearsLabel"))}
      />

      <SupportInfoField
        type={SupportInfoFieldType.CanTakeWorkImmediately}
        info={supportInfo.canTakeWorkImmediately}
        getUpdatedSupportInfo={(value: any) => ({ ...supportInfo, canTakeWorkImmediately: value })}
        leadingSlot={leadingTitle(t("profilePage.supportInfo.canTakeWorkImmediatelyLabel"))}
      />

      <SupportInfoField
        type={SupportInfoFieldType.UnemploymentOrSickLeaveDate}
        info={supportInfo.unemploymentOrSickLeaveDate}
        getUpdatedSupportInfo={(value: any) => ({ ...supportInfo, unemploymentOrSickLeaveDate: value })}
        leadingSlot={leadingTitle(t("profilePage.supportInfo.unemploymentOrSickLeaveDateLabel"))}
      />

      <SupportInfoField
        type={SupportInfoFieldType.WorkTime}
        info={supportInfo.partTimePercentage}
        getUpdatedSupportInfo={(value: any) => ({ ...supportInfo, partTimePercentage: value })}
        leadingSlot={leadingTitle(t("profilePage.supportInfo.workTimeLabel"))}
      />

      <SupportInfoField
        type={SupportInfoFieldType.WorkHours}
        info={supportInfo}
        getUpdatedSupportInfo={(value: any) => {
          return {
            ...supportInfo,
            canWorkDaytime: value.canWorkDaytime,
            canWorkEvening: value.canWorkEvening,
            canWorkNighttime: value.canWorkNighttime,
          };
        }}
        leadingSlot={leadingTitle(t("profilePage.supportInfo.workHoursLabel"))}
      />

      <SupportInfoField
        type={SupportInfoFieldType.HasWorkLimitations}
        info={supportInfo.hasWorkLimitations}
        getUpdatedSupportInfo={(value: any) => ({ ...supportInfo, hasWorkLimitations: value })}
        leadingSlot={leadingTitle(t("profilePage.supportInfo.hasWorkLimitationsLabel"))}
      />

      <SupportInfoField
        type={SupportInfoFieldType.SfiLevel}
        info={supportInfo.sfiLevel}
        getUpdatedSupportInfo={(value: any) => ({ ...supportInfo, sfiLevel: value })}
        leadingSlot={leadingTitle(t("profilePage.supportInfo.sfiLevelLabel"))}
      />

      <SupportInfoField
        type={SupportInfoFieldType.EducationLevel}
        info={supportInfo.educationLevel}
        getUpdatedSupportInfo={(value: any) => ({ ...supportInfo, educationLevel: value })}
        leadingSlot={leadingTitle(t("profilePage.supportInfo.educationLevelLabel"))}
      />
    </Box>
  );
};

export default SupportInfoComponent;
