import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { Application } from "../types/Application";

interface ApplicationsListPageState {
  applications: Application[];
}

const initialState: ApplicationsListPageState = {
  applications: [],
};

export const applicationsListPageSlice = createSlice({
  name: "ApplicationsList",
  initialState,
  reducers: {
    setApplications: (state, action: PayloadAction<Application[]>) => {
      state.applications = action.payload;
    },
    addApplication: (state, action: PayloadAction<Application>) => {
      const newApplication = action.payload;
      // Remove old if any
      state.applications = state.applications.filter((application) => application.id !== newApplication.id);
      state.applications.push(action.payload);
    },
    removeApplication: (state, action: PayloadAction<string>) => {
      state.applications = state.applications.filter((application) => application.id !== action.payload);
    },
    clearApplicationsListPage: (state) => {
      state.applications = [];
    },
  },
});

export const { setApplications, addApplication, removeApplication, clearApplicationsListPage } = applicationsListPageSlice.actions;
export const applicationsListPage = (state: RootState) => state.applicationsListPage;
export default applicationsListPageSlice.reducer;
