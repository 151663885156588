import { Application } from "../../types/Application";
import axiosInstance from "../../utils/AxiosInstance";

export async function getAllApplications(): Promise<Application[]> {
  try {
    const response = await axiosInstance.get<Application[]>("/applications-list");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
