import React, { useEffect, useRef, useState } from "react";
import { Box, Fab, CircularProgress, Button, useTheme, useMediaQuery } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import DocumentPreview from "./DocumentPreview";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { getApplicationDocumentThumbnails } from "../application/ApplicationRepository";
import ApplicationActionModal, { ApplicationActionType } from "./modals/ApplicationActionModal";
import { useNavigate, useParams } from "react-router-dom";
import { APPLICATION_LIST_ROUTE, APPLICATION_ROUTE } from "../../Routes";
import {
  ApplicationDetails,
  setApplicationFromPending,
  updateCoverLetterThumbnail,
  updateResumeThumbnail,
} from "../../reducers/applicantDetilsPageSlice";
import ResumePreviewModal from "./modals/ResumePreviewModal";
import CoverLetterPreviewModal from "./modals/CoverLetterPreviewModal";
import { useTranslation } from "react-i18next";
import { SendApplicationModule } from "./modules/SendApplicationModule";
import { ApplicationInfoModule } from "./modules/ApplicationInfoModule";
import { NudgeModule } from "./modules/NudgeModule";
import { DRAWER_WIDTH } from "../../components/ApplicantAppBar";
import { ChangeApplicationModule } from "./modules/ChangeApplicationModule";

const ApplicationDetailsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const hasFetchedThumbnails = useRef(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentView, setCurrentView] = useState<'details' | 'send'>('details'); // New state to manage views

  const applicationDetails: ApplicationDetails = useAppSelector((state) =>
    id ? state.applicationDetailsPage.applicationsMap[id] : undefined
  ) as ApplicationDetails;

  useEffect(() => {
    if (
      id &&
      !hasFetchedThumbnails.current &&
      (applicationDetails.resumeThumbnail === undefined || applicationDetails.coverLetterThumbnail === undefined)
    ) {
      hasFetchedThumbnails.current = true;

      getApplicationDocumentThumbnails(id)
        .then((response) => {
          dispatch(
            updateResumeThumbnail({
              applicationId: id,
              resumeThumbnail: response.resumeThumbnail,
            })
          );
          dispatch(
            updateCoverLetterThumbnail({
              applicationId: id,
              coverLetterThumbnail: response.coverLetterThumbnail,
            })
          );
        })
        .catch((err) => {
          console.error(t("applicationDetails.page.loadingDocuments"));
        });
    }
  }, [id]);

  const application = applicationDetails.application;
  const openedFromApplicationsList = applicationDetails.openedFromApplicationsList;
  const previewsRef = useRef<HTMLDivElement>(null);
  const previewsMaxWidth = useDynamicMaxWidth(previewsRef);

  const [isPreviewResumeModalOpen, setIsPreviewResumeModalOpen] = useState(false);
  const [isPreviewCoverLetterModalOpen, setIsPreviewCoverLetterModalOpen] = useState(false);

  const [isActionModalOpen, setActionModalOpen] = useState(false);
  const [actionModalType, setActionModalType] = useState(ApplicationActionType.Download);
  const handleOpenModal = (applicationActionType: ApplicationActionType) => {
    setActionModalType(applicationActionType);
    if (!application.hasApplied) {
      setActionModalOpen(true);
    }
  };
  const handleCloseActionModal = () => {
    dispatch(setApplicationFromPending(application.id));
    setActionModalOpen(false);
  };

  const handleBackPressed = () => {
    if (openedFromApplicationsList) {
      navigate(APPLICATION_LIST_ROUTE.path);
    } else {
      navigate(APPLICATION_ROUTE.path);
    }
  };

  const sendApplicationModuleRef = useRef<HTMLDivElement>(null);

  const scrollToApplicationModule = () => {
    sendApplicationModuleRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        py: 8,
        position: "relative",
      }}
    >
      {isUpdating && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={100} />
        </Box>
      )}

      <BackButton onBackPressed={handleBackPressed} />

      {currentView === 'details' && (
        <>
          <NudgeModule
            application={application}
            openedFromApplicationsList={openedFromApplicationsList}
            maxWidth={previewsMaxWidth}
            scrollToApplicationModule={scrollToApplicationModule}
          />

          {openedFromApplicationsList && <ApplicationInfoModule maxWidth={previewsMaxWidth} application={application} />}

          <div ref={previewsRef}>
            <DocumentPreviews
              applicationDetails={applicationDetails}
              onResumePreview={() => {
                setIsPreviewResumeModalOpen(true);
              }}
              onCoverLetterEdit={() => {
                setIsPreviewCoverLetterModalOpen(true);
              }}
            />
          </div>

          {!application.hasApplied && (
            <ChangeApplicationModule maxWidth={previewsMaxWidth} application={applicationDetails.application} setIsUpdating={setIsUpdating} />
          )}

          <Button
            variant="contained"
            color="success"
            onClick={() => setCurrentView('send')}
            sx={{
              position: 'fixed',
              bottom: 24,
              right: 16,
              zIndex: 1000, 
              padding: '12px 24px', // Increase padding for a bigger button
              fontSize: '1.25rem', 
              borderRadius: '8px', 
            }}
          >
            {t('applicationDetails.page.nextButton')}
            <ArrowForward sx={{ marginLeft: '8px' }} />
          </Button>

        </>
      )}

      {currentView === 'send' && (
        <>
          <div ref={sendApplicationModuleRef} style={{ width: previewsMaxWidth }}>
            <SendApplicationModule maxWidth={previewsMaxWidth} handleOpenModal={handleOpenModal} applicationDetails={applicationDetails} />
          </div>

          <Button 
            variant="contained" 
            color="secondary" 
            onClick={() => setCurrentView('details')} 
            sx={{
              position: 'fixed',
              bottom: 24,
              right: 16,
              zIndex: 1000, 
              padding: '12px 24px', // Increase padding for a bigger button
              fontSize: '1.25rem', 
              borderRadius: '8px', 
            }}>
            {t('applicationDetails.page.backButton')}
            <ArrowBack sx={{ marginLeft: '8px' }} />
          </Button>
        </>
      )}

      <ApplicationActionModal
        isOpen={isActionModalOpen}
        actionType={actionModalType}
        applicationId={application.id}
        handleClose={handleCloseActionModal}
      />

      <ResumePreviewModal
        isOpen={isPreviewResumeModalOpen}
        resumeId={application.resumeId}
        handleClose={() => {
          setIsPreviewResumeModalOpen(false);
        }}
      />

      <CoverLetterPreviewModal
        isOpen={isPreviewCoverLetterModalOpen}
        coverLetterId={application.coverLetterId}
        applicationId={application.id}
        handleClose={() => {
          setIsPreviewCoverLetterModalOpen(false);
        }}
      />
    </Box>
  );
};

interface DocumentPreviewsProps {
  applicationDetails: ApplicationDetails;
  onCoverLetterEdit: () => void;
  onResumePreview: () => void;
}

const DocumentPreviews: React.FC<DocumentPreviewsProps> = ({ applicationDetails, onCoverLetterEdit, onResumePreview }) => {
  const { t } = useTranslation();
  const resumeThumbnail = applicationDetails.resumeThumbnail;
  const coverLetterThumbnail = applicationDetails.coverLetterThumbnail;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        justifyContent: "center",
        alignItems: isSmallScreen ? "center" : "flex-start",
      }}
    >
      <DocumentPreview
        content={resumeThumbnail}
        title={t("applicationDetails.page.resume")}
        tooltipText={t("applicationDetails.page.clickToPreview")}
        onClick={onResumePreview}
      />

      <Box sx={{ width: isSmallScreen ? 0 : 20, height: isSmallScreen ? 20 : 0 }} />
      <DocumentPreview
        content={coverLetterThumbnail}
        title={t("applicationDetails.page.coverLetter")}
        tooltipText={t("applicationDetails.page.clickToEdit")}
        onClick={onCoverLetterEdit}
      />
    </Box>
  );
};

interface BackButtonProps {
  onBackPressed: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ onBackPressed }) => {
  return (
    <Fab
      color="primary"
      aria-label="go back"
      sx={{
        position: "fixed",
        top: 16,
        left: DRAWER_WIDTH + 16,
      }}
      onClick={onBackPressed}
    >
      <ArrowBack />
    </Fab>
  );
};

const useDynamicMaxWidth = (ref: React.RefObject<HTMLDivElement>) => {
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        setMaxWidth(ref.current.offsetWidth);
      }
    };

    updateWidth(); // Update when component mounts
    window.addEventListener("resize", updateWidth); // Update on window resize

    return () => {
      window.removeEventListener("resize", updateWidth); // Clean up on component unmount
    };
  }, [ref]); // Make sure to include ref in the dependency array

  return maxWidth; // Return the dynamically calculated maxWidth
};

export default ApplicationDetailsPage;
