import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";
import { addWorkExperience, updateWorkExperience } from "../../reducers/profilePageSlice";
import { submitWorkExperience, updateWorkExperience as apiUpdateWorkExperience } from "./ProfilePageRepository";
import { useAppDispatch } from "../../hooks";
import { WorkExperience } from "../../types/WorkExperience";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface WorkExperienceModalProps {
  initialWorkExperience: WorkExperience | null;
  open: boolean;
  onClose: () => void;
}

const WorkExperienceModal: React.FC<WorkExperienceModalProps> = ({ open, onClose, initialWorkExperience }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [workplace, setWorkplace] = useState(initialWorkExperience?.workplace ?? "");
  const [position, setPosition] = useState(initialWorkExperience?.position ?? "");
  const [fromDate, setFromDate] = useState(dayjs(initialWorkExperience?.fromDate ?? ""));
  const [toDate, setToDate] = useState(dayjs(initialWorkExperience?.toDate ?? ""));
  const [description, setDescription] = useState(initialWorkExperience?.description ?? "");
  const [isEditMode, setIsEditMode] = useState(initialWorkExperience !== null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleAddWorkExperience = async (newWorkExperience: Omit<WorkExperience, "id">) => {
    try {
      const newWorkExperienceData = await submitWorkExperience(newWorkExperience);
      dispatch(addWorkExperience(newWorkExperienceData));
      dispatch(showSnackbar({ message: t("profilePage.workExperience.addSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.workExperience.addError"), severity: "error" }));
    }
  };

  const handleUpdateWorkExperience = async (updatedWorkExperience: WorkExperience) => {
    try {
      const updatedWorkExperienceData = await apiUpdateWorkExperience(updatedWorkExperience);
      dispatch(updateWorkExperience(updatedWorkExperienceData));
      dispatch(showSnackbar({ message: t("profilePage.workExperience.updateSuccess"), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("profilePage.workExperience.updateError"), severity: "error" }));
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      if (workplace.trim() && position.trim() && dayjs(fromDate).isValid() && dayjs(toDate).isValid()) {
        handleSave();
        event.preventDefault();
      }
    } else if (event.key === "Escape") {
        isEditMode ? onClose() : clearData();
    }
  };

  useEffect(() => {
    setWorkplace(initialWorkExperience?.workplace ?? "");
    setPosition(initialWorkExperience?.position ?? "");
    setFromDate(dayjs(initialWorkExperience?.fromDate ?? ""));
    setToDate(dayjs(initialWorkExperience?.toDate ?? ""));
    setDescription(initialWorkExperience?.description ?? "");
    setIsEditMode(initialWorkExperience !== null);
  }, [initialWorkExperience]);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  }, [open]);

  const handleSave = () => {
    if (initialWorkExperience) {
      handleUpdateWorkExperience({
        id: initialWorkExperience.id,
        workplace,
        position,
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        description,
      });
    } else {
      handleAddWorkExperience({
        workplace,
        position,
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        description,
      });
    }
    onClose();
  };

  const clearData = () => {
    setWorkplace("");
    setPosition("");
    setFromDate(dayjs(""));
    setToDate(dayjs(""));
    setDescription("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={isEditMode ? onClose : clearData} fullWidth={true}>
      <DialogTitle>{isEditMode ? t("profilePage.workExperience.editTitle") : t("profilePage.workExperience.addTitle")}</DialogTitle>
      <DialogContent>
        <TextField
          inputRef={inputRef}
          autoFocus
          margin="dense"
          label={t("profilePage.workExperience.workplaceLabel")}
          type="text"
          fullWidth
          value={workplace}
          onChange={(e) => setWorkplace(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <TextField
          margin="dense"
          label={t("profilePage.workExperience.positionLabel")}
          type="text"
          fullWidth
          value={position}
          onChange={(e) => setPosition(e.target.value)}
          onKeyDown={handleKeyPress}
        />

        <Box sx={{ my: 4, display: "flex" }}>
          <DatePicker
            sx={{ mr: 2, flexGrow: 1 }}
            label={t("profilePage.workExperience.fromDateLabel")}
            value={fromDate}
            onChange={(date) => setFromDate(date ?? dayjs(""))}
            maxDate={dayjs()}
            format="YYYY-MM-DD"
          />

          <DatePicker
            sx={{ flexGrow: 1 }}
            label={t("profilePage.workExperience.toDateLabel")}
            value={toDate}
            minDate={fromDate}
            format="YYYY-MM-DD"
            onChange={(date) => setToDate(date ?? dayjs(""))}
          />
        </Box>

        <TextField
          margin="dense"
          label={t("profilePage.workExperience.descriptionLabel")}
          type="text"
          fullWidth
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={isEditMode ? onClose : clearData}
          color="primary">
          {t("generic.cancel")}
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={!workplace.trim() || !position.trim() || !dayjs(fromDate).isValid() || !dayjs(toDate).isValid()}
        >
          {isEditMode ? t("profilePage.workExperience.saveChangesButton") : t("profilePage.workExperience.addButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkExperienceModal;
