import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography, IconButton, MenuItem, SelectChangeEvent, Select, Checkbox, FormControlLabel, Slider } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import SupportInfoModal from "./SupportInfoModal";
import { UpdateApplicantSupportInfo } from "./ProfilePageRepository";
import { updateSupportInfo as apiUpdateSupportInfo } from "./ProfilePageRepository";
import { setSupportInfo } from "../../reducers/profilePageSlice";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";

export enum SupportInfoFieldType {
  Address = "address",
  PhoneNumber = "phoneNumber",
  FirstPreferredJob = "firstPreferredJob",
  SecondPreferredJob = "secondPreferredJob",
  ThirdPreferredJob = "thirdPreferredJob",

  DesiredJobInFiveYears = "desiredJobInFiveYears",
  HasWorkLimitations = "hasWorkLimitations",
  CanTakeWorkImmediately = "canTakeWorkImmediately",
  WorkHours = "workHours",
  WorkTime = "workTime",
  UnemploymentOrSickLeaveDate = "unemploymentOrSickLeaveDate",
  SfiLevel = "sfiLevel",
  EducationLevel = "educationLevel",
  Description = "description",
}

interface SupportInfoFieldProps {
  type: SupportInfoFieldType;
  info: any;
  leadingSlot?: JSX.Element;
  getUpdatedSupportInfo: (value: any) => UpdateApplicantSupportInfo | undefined;
}

const SupportInfoField: React.FC<SupportInfoFieldProps> = ({ type, info, leadingSlot, getUpdatedSupportInfo }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsModalOpen(true);
  };

  const handleDialogClose = () => {
    setIsModalOpen(false);
  };

  const displayInfo = () => {
    if (type === SupportInfoFieldType.Address) {
      return `${info.streetName}, ${info.zipCode}, ${info.city}`;
    } else if (type === SupportInfoFieldType.WorkTime) {
      const value = info as number;
      if (value === 100) {
        return t("profilePage.supportInfo.fullTimeLabel");
      }
      return `${value}%`;
    }
    return info;
  };

  const typeLabel = () => {
    switch (type) {
      case SupportInfoFieldType.Address:
        return t("profilePage.supportInfo.addressLabel");
      case SupportInfoFieldType.PhoneNumber:
        return t("profilePage.supportInfo.phoneNumberLabel");
      case SupportInfoFieldType.FirstPreferredJob:
        return t("profilePage.supportInfo.firstPreferredJobLabel");
      case SupportInfoFieldType.SecondPreferredJob:
        return t("profilePage.supportInfo.secondPreferredJobLabel");
      case SupportInfoFieldType.ThirdPreferredJob:
        return t("profilePage.supportInfo.thirdPreferredJobLabel");
      case SupportInfoFieldType.DesiredJobInFiveYears:
        return t("profilePage.supportInfo.desiredJobInFiveYearsLabel");
      case SupportInfoFieldType.WorkTime:
        return t("profilePage.supportInfo.workTimeLabel");
      case SupportInfoFieldType.UnemploymentOrSickLeaveDate:
        return t("profilePage.supportInfo.unemploymentOrSickLeaveDateLabel");
      default:
        return null;
    }
  };

  const handleSave = async (value: any) => {
    const typeText = typeLabel();
    try {
      const updatedSupportInfo = getUpdatedSupportInfo(value);
      if (!updatedSupportInfo) {
        return;
      }
      const apiUpdatedSupportInfo = await apiUpdateSupportInfo(updatedSupportInfo);
      dispatch(setSupportInfo(apiUpdatedSupportInfo));
      if (typeText) {
        dispatch(showSnackbar({ message: t("profilePage.supportInfo.saveSuccess", { typeLabel: typeText }), severity: "success" }));
      }
    } catch (error) {
      if (typeText) {
        dispatch(showSnackbar({ message: t("profilePage.supportInfo.saveError", { typeLabel: typeText }), severity: "error" }));
      }
    }
  };

  const renderField = () => {
    switch (type) {
      case SupportInfoFieldType.Address:
      case SupportInfoFieldType.PhoneNumber:
      case SupportInfoFieldType.FirstPreferredJob:
      case SupportInfoFieldType.SecondPreferredJob:
      case SupportInfoFieldType.ThirdPreferredJob:
      case SupportInfoFieldType.DesiredJobInFiveYears:
      case SupportInfoFieldType.UnemploymentOrSickLeaveDate:
      case SupportInfoFieldType.WorkTime:
        return renderTextInput(info, type, leadingSlot, displayInfo, handleDialogOpen, t);
      case SupportInfoFieldType.HasWorkLimitations:
      case SupportInfoFieldType.CanTakeWorkImmediately:
        return <SupportInfoSwitchField initialValue={info} leadingSlot={leadingSlot} handleSave={handleSave} t={t} />;

      case SupportInfoFieldType.WorkHours:
        return (
          <WorkHoursField
            dayWorkInitialValue={info.canWorkDaytime}
            eveningWorkInitialValue={info.canWorkEvening}
            nightWorkInitialValue={info.canWorkNighttime}
            leadingSlot={leadingSlot}
            handleSave={handleSave}
            t={t}
          />
        );
      case SupportInfoFieldType.SfiLevel:
        return <SfiLevelField info={info} leadingSlot={leadingSlot} handleSave={handleSave} t={t} />;
      case SupportInfoFieldType.EducationLevel:
        return <EducationLevelField info={info} leadingSlot={leadingSlot} handleSave={handleSave} t={t} />;
    }
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      {renderField()}
      <SupportInfoModal
        type={type}
        open={isModalOpen}
        onClose={handleDialogClose}
        getUpdatedSupportInfo={getUpdatedSupportInfo}
        initialValue={info}
      />
    </Box>
  );
};

const renderTextInput = (
  info: any,
  type: SupportInfoFieldType,
  leadingSlot: JSX.Element | undefined,
  displayInfo: () => string,
  handleDialogOpen: () => void,
  t: any
) => {
  const isInfoIncomplete = () => {
    if (type === SupportInfoFieldType.Address) {
      return !info || !info.streetName || !info.zipCode || !info.city;
    }
    if (type === SupportInfoFieldType.WorkHours) {
      return !info.canWorkDaytime || !info.canWorkEvening || !info.canWorkNighttime;
    }
    return !info;
  };

  const label = () => {
    switch (type) {
      case SupportInfoFieldType.Address:
        return t("profilePage.supportInfo.addAddress");
      case SupportInfoFieldType.PhoneNumber:
        return t("profilePage.supportInfo.addPhoneNumber");
      case SupportInfoFieldType.FirstPreferredJob:
        return t("profilePage.supportInfo.addFirstPreferredJob");
      case SupportInfoFieldType.SecondPreferredJob:
        return t("profilePage.supportInfo.addSecondPreferredJob");
      case SupportInfoFieldType.ThirdPreferredJob:
        return t("profilePage.supportInfo.addThirdPreferredJob");
      case SupportInfoFieldType.DesiredJobInFiveYears:
        return t("profilePage.supportInfo.addDesiredJobInFiveYears");
      case SupportInfoFieldType.WorkTime:
        return t("profilePage.supportInfo.addWorkPercentage");
      case SupportInfoFieldType.UnemploymentOrSickLeaveDate:
        return t("profilePage.supportInfo.addUnemploymentDate");
      default:
        return "";
    }
  };

  return (
    <Box sx={{ textAlign: "left" }} onClick={handleDialogOpen}>
      {isInfoIncomplete() ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "&:hover span": {
              textDecoration: "underline",
            },
          }}
        >
          <IconButton sx={{ mr: 1, color: "secondary.main" }}>
            <AddCircleOutline sx={{ fontSize: 30 }} />
          </IconButton>
          <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
            <Box component="span" sx={{ display: "block" }}>
              {label()}
            </Box>
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "&:hover span": {
              textDecoration: "underline",
            },
            "&:hover .edit-icon": {
              visibility: "visible",
            },
          }}
        >
          {leadingSlot ? leadingSlot : <></>}
          <Typography variant="subtitle1" sx={{}}>
            <Box component="span" sx={{ display: "block" }}>
              {displayInfo()}
            </Box>
          </Typography>
          <IconButton onClick={handleDialogOpen} className="edit-icon" sx={{ mx: 1, visibility: "hidden" }}>
            <Edit />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

interface SupportInfoSwitchFieldProps {
  initialValue: boolean;
  leadingSlot?: JSX.Element;
  handleSave: (value: any) => void;
  t: any;
}

const SupportInfoSwitchField: React.FC<SupportInfoSwitchFieldProps> = ({ initialValue, leadingSlot, handleSave }) => {
  const [value, setValue] = useState(initialValue);
  return (
    <Box display={"flex"} flexDirection={"row"}>
      {leadingSlot ? leadingSlot : <></>}
      <Checkbox
        checked={value}
        onChange={(event) => {
          setValue(event.target.checked);
          handleSave(event.target.checked);
        }}
      />
    </Box>
  );
};

interface WorkHoursFieldProps {
  dayWorkInitialValue: boolean;
  eveningWorkInitialValue: boolean;
  nightWorkInitialValue: boolean;
  leadingSlot?: JSX.Element;
  handleSave: (value: any) => void;
  t: any;
}

const WorkHoursField: React.FC<WorkHoursFieldProps> = ({
  dayWorkInitialValue,
  eveningWorkInitialValue,
  nightWorkInitialValue,
  leadingSlot,
  handleSave,
  t,
}) => {
  const [value, setValue] = useState({
    canWorkDaytime: dayWorkInitialValue,
    canWorkEvening: eveningWorkInitialValue,
    canWorkNighttime: nightWorkInitialValue,
  });
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box display="flex" flexDirection="row" alignItems="center">
        {leadingSlot ? leadingSlot : <></>}
        <Typography variant="subtitle1">{t("profilePage.supportInfo.canWorkDaytime")}</Typography>
        <Checkbox
          checked={value.canWorkDaytime}
          onChange={(event) => {
            const newValue = {
              ...value,
              canWorkDaytime: event.target.checked,
            };
            setValue(newValue);
            handleSave(newValue);
          }}
        />

        <Typography variant="subtitle1" sx={{ ml: 5 }}>
          {t("profilePage.supportInfo.canWorkEvening")}
        </Typography>
        <Checkbox
          checked={value.canWorkEvening}
          onChange={(event) => {
            const newValue = {
              ...value,
              canWorkEvening: event.target.checked,
            };
            setValue(newValue);
            handleSave(newValue);
          }}
        />

        <Typography variant="subtitle1" sx={{ ml: 5 }}>
          {t("profilePage.supportInfo.canWorkNighttime")}
        </Typography>
        <Checkbox
          checked={value.canWorkNighttime}
          onChange={(event) => {
            const newValue = {
              ...value,
              canWorkNighttime: event.target.checked,
            };
            setValue(newValue);
            handleSave(newValue);
          }}
        />
      </Box>
    </Box>
  );
};

interface EducationLevelFieldProps {
  info: string;
  leadingSlot: JSX.Element | undefined;
  handleSave: (value: any) => void;
  t: any;
}

enum EducationLevels {
  NO_EDUCATION = "noEducation",
  LESS_THAN_SEVEN = "lessThanSevenYears",
  SEVEN_TO_NINE = "sevenToNineYears",
  HIGH_SCHOOL = "highSchool",
  HIGHER_EDU = "higherEducation",
}

const EducationLevelField: React.FC<EducationLevelFieldProps> = ({ info, leadingSlot, handleSave, t }) => {
  const [value, setValue] = useState(info);

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {leadingSlot && leadingSlot}
      <Select
        value={value}
        onChange={(event: SelectChangeEvent<string>) => {
          const selectedValue = event.target.value;
          setValue(selectedValue);
          handleSave(selectedValue);
        }}
        sx={{ minWidth: "200px", ml: 4 }}
      >
        {Object.entries(EducationLevels).map(([key, displayText]) => (
          <MenuItem key={key} value={key}>
            {t(`profilePage.supportInfo.${displayText}`)}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

interface WorkTimeFieldProps {
  info: number;
  leadingSlot: JSX.Element | undefined;
  handleSave: (value: any) => void;
  t: any;
}

const WorkTimeField: React.FC<WorkTimeFieldProps> = ({ info, leadingSlot, handleSave, t }) => {
  const [value, setValue] = useState(info);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  function valuetext(value: number) {
    return `${value}`;
  }

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (newValue !== value) {
        handleSave(newValue as number);
      }
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {leadingSlot && leadingSlot}

      <Slider
        defaultValue={value}
        getAriaValueText={valuetext}
        step={5}
        marks
        min={0}
        max={100}
        valueLabelDisplay="auto"
        sx={{ minWidth: "200px", maxWidth: "300px" }}
        value={value}
        onChange={handleChange}
      />

      <Typography variant="subtitle1" sx={{ ml: 5 }}>
        {value === 100
          ? t("profilePage.supportInfo.fullTimeLabel")
          : value === 0
          ? t("profilePage.supportInfo.notWorkingLabel")
          : `${value}%`}
      </Typography>
    </Box>
  );
};

interface SfiLevelFieldProps {
  info: string;
  leadingSlot?: React.ReactNode;
  handleSave: (value: string) => void;
  t: any;
}

enum SfiLevels {
  MOTHER_TONGUE = "MOTHER_TONGUE",
  B = "B",
  C = "C",
  D = "D",
  SAS_G = "SAS G",
}

const SfiLevelField: React.FC<SfiLevelFieldProps> = ({ info, leadingSlot, handleSave, t }) => {
  const [isMotherTongue, setIsMotherTongue] = useState(info === SfiLevels.MOTHER_TONGUE);
  const [value, setValue] = useState(isMotherTongue ? SfiLevels.MOTHER_TONGUE : info);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsMotherTongue(checked);
    if (checked) {
      handleSave(SfiLevels.MOTHER_TONGUE);
    } else if (value === SfiLevels.MOTHER_TONGUE) {
      setValue(""); // Reset value if the checkbox was checked before
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setValue(selectedValue);
    handleSave(selectedValue);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {leadingSlot && leadingSlot}
      <FormControlLabel
        control={<Checkbox checked={isMotherTongue} onChange={handleCheckboxChange} name="motherTongue" />}
        label={t("profilePage.supportInfo.motherTongueLabel")}
        labelPlacement="start"
        sx={{ ml: 0 }}
      />
      {!isMotherTongue && (
        <Select value={value} onChange={handleSelectChange} sx={{ minWidth: "200px", ml: 4 }}>
          {Object.entries(SfiLevels)
            .filter(([key]) => key !== "MOTHER_TONGUE")
            .map(([key, displayText]) => (
              <MenuItem key={key} value={key}>
                {displayText}
              </MenuItem>
            ))}
        </Select>
      )}
    </Box>
  );
};

export default SupportInfoField;
