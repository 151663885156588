import React, { ReactNode } from "react";
import { Box, Backdrop, Modal, Fade, SxProps, Theme } from "@mui/material";

interface MaijaModalProps {
  isOpen: boolean;
  handleClose: () => void;
  width?: string;
  maxWidth?: string;
  maxHeight?: string;
  overflowY?: string;
  children: ReactNode;
  p?: number;
  sx?: SxProps<Theme>;
}

const MaijaModal: React.FC<MaijaModalProps> = ({ children, isOpen, width, maxWidth, maxHeight, overflowY, p, sx, handleClose }) => {
  const defaultStyle: SxProps<Theme> = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width ?? "80%",
    maxWidth: maxWidth ?? "auto",
    maxHeight: maxHeight ?? "auto",
    bgcolor: "#fafafa",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    overflowY: overflowY ?? "auto",
    p: p ?? 5,
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <Box sx={{ ...defaultStyle, ...sx }}>{children}</Box>
      </Fade>
    </Modal>
  );
};

export default MaijaModal;
