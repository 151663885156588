import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  List,
  ListItemText,
  Paper,
  Typography,
  LinearProgress,
  ListItemButton,
  Card,
  Avatar,
  IconButton,
  Tooltip,
  Fade,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import MaijaContainer from "../../components/MaijaContainer";
import SkillsComponent from "./SkillsComponent";
import {
  fetchEducations,
  fetchLanguages,
  fetchSelfAssessment,
  fetchSkills,
  fetchSupportInfo,
  fetchWorkExperiences,
  updateProfilePicture,
} from "./ProfilePageRepository";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  ProfilePageState,
  setEducations,
  setLanguages,
  setProfilePageData,
  setSelfAssessmentQuestions,
  setSkills,
  setSupportInfo,
  setWorkExperiences,
} from "../../reducers/profilePageSlice";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";
import LanguagesComponent from "./LanguagesComponent";
import WorkExperiencesComponent from "./WorkExperienceComponent";
import EducationsComponent from "./EducationComponent";
import SupportInfoComponent from "./SupportInfoComponent";
import { CameraAlt, Home } from "@mui/icons-material";
import { RootState } from "../../store";
import { setProfilePictureUrl } from "../../reducers/userSlice";
import SupportInfoField, { SupportInfoFieldType } from "./SupportInfoField";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import DisplayedValue from "../../components/DisplayedValue";
import SelfAssessmentComponent from "./SelfAssessmentComponent";
import {
  calculateCompletionPercentage,
  educationsCompletion,
  introductionCompletion,
  languagesCompletion,
  profileDataIsPrestineLoading,
  selfAssessmentCompletion,
  skillsCompletion,
  supportInfoCompletion,
  workExperiencesCompletion,
} from "./profileCompletionCalculations";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green } from "@mui/material/colors";
import IntroductionComponent from "./IntroductionComponent";
import CompletedProfileModal from "./CompletedProfileModal";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";

const componentsMap: { [key: string]: React.FC } = {
  "Work experience": WorkExperiencesComponent,
  Education: EducationsComponent,
  Skills: SkillsComponent,
  Language: LanguagesComponent,
  "Work situation": SupportInfoComponent,
  "Self assessment": SelfAssessmentComponent,
  Introduction: IntroductionComponent,
};

const ProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const [selectedComponent, setSelectedComponent] = useState<keyof typeof componentsMap>("Work experience");
  const listKeys = Object.keys(componentsMap).map((key) => t(`profilePage.components.${key}`));
  const SelectedComponent = componentsMap[selectedComponent];

  const loading = useFetchProfileData();
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [openProfileDoneModal, setOpenProfileDoneModal] = useState(false);
  const pageState = useAppSelector((state: RootState) => state.profilePage);
  const completionPercentageRef = useRef(completionPercentage);

  useEffect(() => {
    if (!loading) {
      const newCompletionPercentage = calculateCompletionPercentage(pageState);
      const oldCompletionPercentage = completionPercentageRef.current;

      setCompletionPercentage(newCompletionPercentage);
      completionPercentageRef.current = newCompletionPercentage;

      if (oldCompletionPercentage !== 100 && oldCompletionPercentage > 0 && newCompletionPercentage >= 100) {
        setOpenProfileDoneModal(true);
      }
    }
  }, [loading, pageState]);

  return (
    <MaijaContainer sx={{ pb: 10, px: { xs: 2, sm: 4, md: 6 } }} maxWidth={false}>
      {completionPercentage < 100 && (
        <Box sx={{ width: "100%", mt: 10, mb: 5 }}>
          <LinearProgress variant="determinate" value={completionPercentage} color="primary" />
        </Box>
      )}
      {completionPercentage === 100 && <Box sx={{ width: "100%", mt: 10, mb: 5 }}></Box>}
      <ProfileCard />
      <Paper sx={{ display: "flex", borderRadius: 6, flexDirection: { xs: "column", md: "row" } }}>
        <List
          sx={{
            width: { xs: "100%", md: "230px" },
            display: { xs: "flex", md: "block" },
            flexDirection: { xs: "row", md: "column" },
            overflowX: { xs: "scroll", md: "visible" },
          }}
        >
          {listKeys.map((key, index) => {
            const isCompleted =
              (key === t("profilePage.components.Work experience") &&
                pageState.workExperiences &&
                workExperiencesCompletion(pageState.workExperiences) >= 1) ||
              (key === t("profilePage.components.Education") && pageState.educations && educationsCompletion(pageState.educations) >= 1) ||
              (key === t("profilePage.components.Skills") && pageState.skills && skillsCompletion(pageState.skills) >= 1) ||
              (key === t("profilePage.components.Language") && pageState.languages && languagesCompletion(pageState.languages) >= 1) ||
              (key === t("profilePage.components.Work situation") &&
                pageState.supportInfo &&
                supportInfoCompletion(pageState.supportInfo) >= 1) ||
              (key === t("profilePage.components.Introduction") &&
                pageState.supportInfo &&
                introductionCompletion(pageState.supportInfo) >= 1) ||
              (key === t("profilePage.components.Self assessment") &&
                pageState.selfAssessmentQuestions &&
                selfAssessmentCompletion(pageState.selfAssessmentQuestions) >= 1);

            return (
              <ListItemButton
                sx={{ m: 2, borderRadius: 6, flex: { xs: 1, md: "none" } }}
                key={key}
                onClick={() => setSelectedComponent(Object.keys(componentsMap)[index] as keyof typeof componentsMap)}
              >
                <ListItemText primary={key} />
                {!loading && isCompleted && completionPercentage < 100 && (
                  <Fade in={true} timeout={400}>
                    <CheckCircleIcon style={{ fontSize: 30, color: green[500] }} />
                  </Fade>
                )}
              </ListItemButton>
            );
          })}
        </List>
        <Divider sx={{ my: { xs: 0, md: 2 } }} orientation="vertical" flexItem />
        <Box sx={{ flex: 1, p: 4 }}>
          <SelectedComponent />
        </Box>
      </Paper>
      <CompletedProfileModal isOpen={openProfileDoneModal} handleClose={() => setOpenProfileDoneModal(false)} />
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={profileDataIsPrestineLoading(pageState)}>
        <MaijaLoadingPage isFullscreen={true} />
      </Backdrop>
    </MaijaContainer>
  );
};

const ProfileCard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const profilePictureUrl = useAppSelector((state: RootState) => state.user.user?.profileIconUrl);
  const supportInfo = useAppSelector((state: RootState) => state.profilePage.supportInfo);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result as string;
        try {
          const profileIconUrl = await updateProfilePicture(base64String);
          dispatch(setProfilePictureUrl(profileIconUrl));
        } catch (error) {
          dispatch(
            showSnackbar({
              message: t("profilePage.supportInfo.saveError", { typeLabel: t("profilePage.supportInfo.profilePictureLabel") }),
              severity: "error",
            })
          );
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Card sx={{ mb: 5, borderRadius: 6 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left", p: 2, pb: 5, flexDirection: { xs: "column", md: "row" } }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "inline-flex", justifyContent: "center", position: "relative" }}>
            <Avatar sx={{ width: 170, height: 170, marginLeft: { xs: 0, md: 7 } }} src={profilePictureUrl || undefined}>
              {!profilePictureUrl && (
                <IconButton sx={{ width: 170, height: 170 }} component="label">
                  <CameraAlt />
                  <input type="file" hidden onChange={handleFileChange} />
                </IconButton>
              )}
            </Avatar>
            {profilePictureUrl && (
              <IconButton
                component="label"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  backgroundColor: "white",
                  borderRadius: "50%",
                  width: 40,
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                  },
                }}
              >
                <CameraAlt />
                <input type="file" hidden onChange={handleFileChange} />
              </IconButton>
            )}
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", ml: { xs: 0, md: 7 }, my: 4, flexGrow: 1 }}>
          <Typography variant="h4" sx={{ textAlign: { xs: "center", md: "left" } }}>
            {supportInfo?.name}
          </Typography>
          {supportInfo?.firstPreferredJob && (
            <Typography variant="subtitle2" sx={{ mb: 5, textAlign: { xs: "center", md: "left" } }}>
              {supportInfo.firstPreferredJob}
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              flexGrow: 1,
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
              <SupportInfoField
                type={SupportInfoFieldType.Address}
                info={supportInfo}
                getUpdatedSupportInfo={(value: any) =>
                  supportInfo
                    ? {
                        ...supportInfo,
                        streetName: value.streetName,
                        zipCode: value.zipCode,
                        city: value.city,
                      }
                    : undefined
                }
                leadingSlot={<Home sx={{ mr: 4, fontSize: 25 }} />}
              />
              <SupportInfoField
                type={SupportInfoFieldType.PhoneNumber}
                info={supportInfo?.phoneNumber}
                getUpdatedSupportInfo={(value: any) =>
                  supportInfo
                    ? {
                        ...supportInfo,
                        phoneNumber: value,
                      }
                    : undefined
                }
                leadingSlot={<LocalPhoneIcon sx={{ mr: 4, fontSize: 25 }} />}
              />
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Tooltip title={t("profilePage.supportInfo.emailTooltip")} arrow>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <EmailIcon sx={{ mr: 4, fontSize: 25 }} />
                    <Typography>{supportInfo?.email}</Typography>
                  </Box>
                </Tooltip>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minWidth: 200,
                flexGrow: 5,
                mr: 2,
                mt: { xs: 2, md: 0 },
              }}
            >
              <Typography variant="h6">{t("profilePage.supportInfo.jobCoachLabel")}</Typography>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mt: 2 }}>
                <Avatar
                  alt={supportInfo?.jobCoachName || undefined}
                  src={supportInfo?.jobCoachProfileIconUrl || "text-to-trigger-alt"}
                  sx={{ width: 50, height: 50, mr: 1 }}
                />
                <Box
                  sx={{
                    ml: 2,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  <DisplayedValue name={t("profilePage.supportInfo.jobCoachNameLabel")} value={supportInfo?.jobCoachName} />
                  <DisplayedValue name={t("profilePage.supportInfo.jobCoachEmailLabel")} value={supportInfo?.jobCoachEmail} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

const useFetchProfileData = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [workExperiences, educations, skills, languages, supportInfo, selfAssessmentQuestions] = await Promise.all([
          fetchWorkExperiences(),
          fetchEducations(),
          fetchSkills(),
          fetchLanguages(),
          fetchSupportInfo(),
          fetchSelfAssessment(),
        ]);

        const profileData: ProfilePageState = {
          workExperiences,
          educations,
          skills,
          languages,
          supportInfo,
          selfAssessmentQuestions: selfAssessmentQuestions,
        };

        dispatch(setProfilePageData(profileData));
      } catch (error) {
        dispatch(showSnackbar({ message: t("profilePage.supportInfo.loadError"), severity: "error" }));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, t]);

  return loading;
};

export default ProfilePage;
