export class MaijaError extends Error {
  type?: string;

  constructor(message: string, type: string = "general") {
    super(message);
    this.type = type;
    this.name = "MaijaApiError";

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MaijaError);
    }
  }
}
