import React, { useEffect, useState } from "react";
import { Paper, styled, Tooltip, Typography, Skeleton } from "@mui/material";
import { Box, SxProps, Theme } from "@mui/system";
import { useTranslation } from "react-i18next";

interface DocumentPreviewProps {
  content?: string;
  tooltipText: string;
  title?: string;
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

const calculateA4WidthFromHeight = (height: number) => {
  return height / 1.414; // A4 aspect ratio (21cm/29.7cm)
};

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ content, title, tooltipText, sx, onClick }) => {
  const { t } = useTranslation();
  const heightWindowFraction = 0.6;
  const [height, setHeight] = useState(window.innerHeight * heightWindowFraction);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const handleTooltipClose = () => setTooltipOpen(false);
  const handleTooltipOpen = () => setTooltipOpen(true);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight * heightWindowFraction);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isLoading = content === undefined;

  const A4Paper = styled(Paper)(({ theme }) => ({
    height: "60vh",
    width: `${calculateA4WidthFromHeight(height)}px`,
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out", // Smooth transition for transform and shadow
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: "0 4px 16px rgba(0,0,0,0.2)",
    },
  }));

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {title && (
        <Typography variant="h6" sx={{ mb: 2, alignSelf: "flex-start" }}>
          {title}
        </Typography>
      )}
      <Tooltip
        title={tooltipText}
        placement="top"
        arrow
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        onFocus={handleTooltipOpen}
        onBlur={handleTooltipClose}
      >
        <div>
          {isLoading ? (
            <Skeleton variant="rounded" animation="wave" width={calculateA4WidthFromHeight(height)} height="60vh" sx={sx} />
          ) : (
            <A4Paper
              elevation={10}
              sx={sx}
              onClick={() => {
                handleTooltipClose();
                onClick?.();
              }}
            >
              <img
                src={`data:image/jpeg;base64,${content}`}
                alt={t("applicationDetails.documentPreview.documentThumbnail")}
                style={{ width: "100%", height: "auto", borderRadius: "4px" }}
              />
            </A4Paper>
          )}
        </div>
      </Tooltip>
    </Box>
  );
};

export default DocumentPreview;
